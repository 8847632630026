@import "../../styles/variables.scss";

.header {
  padding-bottom: $padding/2;
  margin-bottom: $padding/2;
  border-bottom: solid 1px $borderGrey;
  font-size: $fontMd;
  font-weight: $fontSemibold;
}

.HealthHistory {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;

    h3 {
      font-size: $fontMd;
      color: $primary50;
      font-weight: $fontSemibold;
    }

    a {
      font-size: $fontBase;
      text-decoration: underline;
      color: $primary200;
      cursor: pointer;
    }
  }

  .historyCards {
    // Extra margin to account for the intercom button thing
    margin-bottom: $padding * 6;
  }

  .historyCard {
    background-color: $primary500;
    border-radius: $borderRadius;
    padding: $padding/ 2 0;
    margin-bottom: $padding;

    .cardHeader {
      display: flex;
      width: 100%;
      gap: $padding/2;
      align-items: center;
      padding: $padding / 2 $padding;
      cursor: pointer;

      .alert {
        display: flex;
        align-items: center;
        gap: $padding/8;
        font-size: $fontSmall;
        font-weight: $fontSemibold;
        background-color: $warning;
        color: $warningText;
        padding: 0 $padding/4;
        border-radius: $borderRadius/2;
        &.normal {
          background-color: $success;
          color: $successText;
        }
      }

      p {
        font-weight: $fontSemibold;
        color: $primary100;
      }

      .showHideIcon {
        margin-left: auto;
      }
    }

    .cardBody {
      height: 0;
      display: none;

      .newEntryContainer {
        display: flex;
        width: 100%;
        padding: $padding / 2 $padding;
        align-items: center;
      }

      .editEntryContainer {
        display: flex;
        width: 100%;
        padding: 0;
        align-items: center;
      }

      .inputContainer {
        border-color: $borderGrey;

        input {
          margin: 0;
          border: none;
          padding: $padding / 2;
          max-width: 100%;

          &::placeholder {
            font-size: $fontBase;
          }
        }
      }
    }

    .historyEntries {
      list-style: none;

      .historyEntry {
        display: flex;
        padding: $padding / 2 $padding;

        &:hover {
          background: $primary400;
          cursor: pointer;
        }

        .title {
          flex: 4;
          color: $white;
        }
        .date {
          flex: 1;
          font-size: $fontSmall;
          color: $gray800;
          white-space: nowrap;
        }
        .options {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            stroke: $gray700;
            transition: all 0.2s;
          }

          &:hover {
            svg {
              transform: scaleX(1.2);
            }
          }
        }
      }
    }

    .newEntry {
      width: 100%;
      padding: $padding/2 $padding;
      text-align: center;
      line-height: 15px;

      &:hover {
        background: $primary400;
      }

      svg {
        stroke: $primary800;
        height: 15px;
        width: 15px;
      }
    }
  }
}
