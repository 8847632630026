@import "@/styles/variables.scss";

.RxModal {
  @include flexCol;
  gap: $padding;
  height: 90vh;
  width: 60vw;

  .patientWrapper {
    display: grid;
    grid-template-columns: 1fr 200px;
    align-items: center;
    gap: $padding;
    button {
      margin-left: auto;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
