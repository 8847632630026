@import "@/styles/variables.scss";

.PatientCheckout {
  .checkoutGrid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: $padding;
    align-items: start;
    padding: 0 $padding;

    .pillWrapper {
      @include flex;
      padding: $padding/2 0;
      gap: $padding;
      flex-wrap: wrap;
    }
    .spanAll {
      grid-column: span 2;
      width: 100%;
    }
    .span2 {
      grid-column: span 2;
      width: 100%;
      &.sectionTitle {
        margin-top: $padding;
        padding: $padding;
        background-color: $gray50;
        font-weight: $fontSemibold;
        color: $parioBlue;
        @include roundTop;
      }
    }
    .span3 {
      grid-column: span 3;
      width: 100%;
      &.sectionTitle {
        margin-top: $padding;
        padding: $padding;
        background-color: $gray50;
        font-weight: $fontSemibold;
        @include roundTop;
      }
    }
    .header {
      display: grid;
      grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr 2fr;
      grid-column: span 2;
      width: 100%;
      font-size: $fontSmall;
      font-weight: $fontSemibold;
    }
    .cptRow {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: $padding;
      align-items: start;
      margin-top: $padding/4;
      padding-left: $padding;
    }
    .addCptRow {
      display: grid;
      grid-template-columns: 1fr;
      gap: $padding;
      align-items: start;
      margin-top: $padding/4;
      padding-left: $padding;
      // span the whole grid
    }
    .chargeRow {
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1fr 1fr 30px;
      gap: $padding;
      align-items: start;
      justify-items: start;
      padding-right: $padding;
    }

    .paymentRow {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 3fr;
      gap: $padding;
      align-items: start;
      justify-items: end;
      padding: 0 $padding;

      label {
        display: flex;
        height: 30px;
      }
    }

    .total {
      font-weight: $fontSemibold;
      margin-top: $padding;
    }
  }

  .transactions {
    @include flexCol;
    gap: $padding;
    margin: $padding;
    margin-top: 0;
  }
  .patientBalance {
    display: flex;
    justify-content: space-between;
    padding: $padding/2;
    background-color: $gray50;
    margin: $padding;
    border-radius: $borderRadius;
    font-weight: $fontBold;
  }

  .paymentsWrapper {
    grid-column: span 3;
    @include flexCol;
    gap: $padding;
  }

  .completedAlert {
    display: flex;
    align-items: center;
    gap: $padding;
    padding: $padding;
    background-color: $success25;
    border-radius: $borderRadius;
    color: $successText;
    font-weight: $fontSemibold;
    margin: $padding;
    margin-bottom: 0;
  }

  .queuedTransactions {
    display: grid;
    grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr 2fr;
    align-items: center;
    gap: $padding;
    padding: $padding;
    background-color: $warning25;
    border-radius: $borderRadius;
    &:not(.headers) {
      color: $warningText;
    }
    margin: $padding;
    margin-bottom: 0;
  }

  .headers {
    margin-top: -$padding;
    padding: $padding/2 $padding;
    background-color: $gray50;
  }
}
