@import "@/styles/variables.scss";

.fileList {
  @include flexCol;
  gap: $padding;
}

.file {
  @include flex;
  justify-content: space-between;
  gap: $padding/2;
  overflow: hidden;
  background-color: $blue25;
  padding: $padding;
  border-radius: $borderRadius;

  &.selected {
    background-color: $blue100;
    border: 1px solid $blue700;
    .fileName {
      color: $blue700;
    }
  }

  .fileName {
    white-space: nowrap;
    overflow: hidden;
    color: $gray500;
  }

  .fileTags {
    @include flex;
    margin-top: $padding/2;
    gap: $padding/2;
  }
}

.fileViewer {
  width: 100%;
  height: 100%;
  min-height: 50vh;

  .contentViewer {
    height: calc(100% - 52px); // magic number from left pane header height
  }
}

.fileUploader {
  padding: $padding;
  @include flexCol;
  gap: $padding/2;

  .dropzone {
    border: 2px dashed $gray300;
    border-radius: $borderRadius;
    padding: $padding;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;

    &:hover,
    &.isDragActive {
      border-color: $blue700;
    }

    .uploadIcon {
      color: $gray400;
      margin-bottom: $padding/2;
    }

    .uploadText {
      color: $gray600;

      button {
        color: $blue700;
        text-decoration: underline;
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
      }
    }
  }
}
