@import "@/styles/variables.scss";

.SelectFile {
  display: flex;
  @include center;
  width: 208px; // 240px - 16px (padding) fixed width here for easy alignment and ellipsis
  position: relative;
  gap: $padding/2;
  background-color: $grey200;
  padding: $padding/2 $padding/1.5;
  border-radius: $borderRadius;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 150ms ease;
  .iconBackground {
    @include flex;
    @include center;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    transition: all 150ms ease;
  }
  &.selected {
    border-color: $primary200;
    .iconBackground {
      background-color: $primary200;
    }
  }
  .checkIcon {
    background-color: $success50;
    width: 20px;
    height: 20px;
    display: flex;
    @include center;
    position: absolute;
    border-radius: 50%;
    right: -$padding/2;
    top: -$padding/2;
    opacity: 0;
    transition: all 150px ease;
    &.visible {
      opacity: 1;
    }
  }

  .textWrapper {
    min-width: 0; // This is crucial for text-overflow to work
    flex: 1;
    .fileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; // Ensures the text doesn't overflow its container
    }
  }
}
