@import "@/styles/variables.scss";

.Layout {
  max-width: 1920px;
  margin: 0 auto;
}

.Footer {
  @include flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  padding: $padding * 1.5;
  color: $secondary;
  font-weight: $fontReg; // TODO: load more font weights
  border-top: $gridBorder;

  a {
    text-decoration: underline;
  }

  font-size: $fontSmall;

  @media (min-width: $tablet) {
    font-size: $fontBase;
  }

  // do not print footer
  @media print {
    display: none;
  }
}

.Navbar {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: $spaceLg $spaceXL;

  @media (max-width: $tablet) {
    padding: $spaceMd;
  }

  background-color: $primary;
  box-shadow: 0px 4px 8px rgba(239, 248, 255, 1);
  z-index: 1;

  a {
    text-decoration: none;
  }
}

.PrivateLayout {
  @include flex;
  scroll-behavior: smooth;
  @media (max-width: $tablet) {
    overflow-x: auto;
  }
  .contentWrapper {
    width: calc(100% - $sidebarWidth);
    @media (min-width: 500px) {
      margin-left: $sidebarWidth;
    }
  }

  // remove margin on main content on print (sidebar does not print)
  @media print {
    .contentWrapper {
      margin-left: 0;
      width: 100%;
    }
  }
}

.MobileLayout {
  .MobileNav {
    @include flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding/2 $padding;
    background-color: $parioBlue;

    .right {
      display: flex;
      align-items: center;
      gap: $padding;
    }
  }
}
