@import "@/styles/variables.scss";

.AvatarPlaceholder {
  @include flex;
  @include center;
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: $primary100;
  color: $parioBlue;
  flex-shrink: 0;
  border: 1px solid $blue100;
  text-transform: uppercase;

  &.inverse {
    background-color: #c2d2db;
    color: $blue900;
  }

  &.large {
    min-width: 60px;
    height: 60px;
  }

  &.small {
    min-width: 32px;
    width: 32px;
    height: 32px;
    font-size: $fontSmall;
    font-weight: $fontSemibold;
    border: none;
  }

  &.xSmall {
    min-width: 20px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    font-weight: $fontSemibold;
    border: none;
  }
}
