@import "@/styles/variables.scss";

$widthOutwardCorner: 10px;

.Tabbed {
  .tabMenu {
    @include flex;
    align-items: center;
    gap: $spaceMd;
    margin: 0;
    overflow-x: auto;
    &.unpadded {
      margin: 0;
    }
    &.leftAligned {
      justify-content: flex-end;
      width: unset;
      margin-left: calc(100% - 200px);
    }
    .end {
      margin-left: auto;
    }
  }
  .tabContent {
    border-top: 3px solid $primary500;
    @include flexCol;
    gap: $padding;
    padding: $padding 0;
  }

  .Tab {
    background-color: transparent;
    color: $gray500;
    font-weight: $fontSemibold;
    padding: 6px 12px 4px 12px;
    border-bottom: 2px solid transparent;
    flex-shrink: 0;
    position: relative;
    border-radius: 6px 6px 0 0;
    @include textSmMedium;
    display: flex;
    @include center;
    gap: $padding / 2;
    text-transform: capitalize;
    white-space: nowrap;

    &.selected {
      background-color: $primary50;
      color: $primary700;

      &.selected {
        background-color: $primary500;
        color: $white;
      }
    }
  }

  .cornerRounder {
    position: relative;
  }
  // more subtle tags for inside elements
  &.secondary {
    .tabContent {
      border-top: none;
    }
    .cornerRounder {
      &:before,
      &:after {
        content: none;
      }
      .Tab {
        border-bottom: 2px solid transparent;
        border-radius: 0;
        background-color: transparent;
        padding: 1px 4px $widthOutwardCorner 4px;
        &.selected {
          color: $primary700;
          border-color: $primary700;
        }
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }

  // Legacy styles
  &.legacy {
    .tabMenu {
      margin: $padding $padding * 1.5;
      &.unpadded {
        margin: 0;
      }
    }
    .tabContent {
      border-top: none;
    }
    .cornerRounder {
      &:before,
      &:after {
        content: none;
      }
      .Tab {
        &.selected {
          background-color: $primary50;
          color: $primary700;
          border-radius: 6px;
        }
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
}
