@import "@/styles/variables.scss";

.DetailTimeline {
    @include flexCol;
    gap: $padding * 2;
    flex-shrink: 0;
    position: relative;
    overflow-y: scroll;
    min-height: 400px;
    .event {
        display: flex;
        align-items: center;
        gap: $padding;
    }
    .marker {
        width: 24px;
        height: 24px;
        background-color: $blue50;
        border-radius: 50%;
        outline: 4px solid $blue100;
        display: flex;
        @include center;
        position: relative;
        transition: all 150ms ease;
        &:hover, &.selected {
            background-color: $blue100;
            outline: 4px solid $primary600;
        }
        .innerMarker {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary600;
        }
        &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: calc(($padding * 2) + 2px);
            background-color: $grey200;
            top: calc(($padding * 2) - 4px);
        }
    }

    .downArrow {
        position: absolute;
        bottom: - 2.5 * $padding;
        left: 131px;
    }
}
