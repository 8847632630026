@import "@/styles/variables.scss";

.Prenatal {
  width: 100%;
  .diagnoses {
    @include flex;
    align-items: center;
    justify-content: flex-end;
    height: 75px;
    background-color: rgba(240, 240, 240, 1);
    padding: $spaceLg;
  }

  .flowWrapper {
    padding: $spaceLg;
  }

  .header {
    background-color: $lightBlue;
    padding: $padding;
    @include roundTop;
    h3 {
      font-weight: $fontSemibold;
      color: $medGrey;
      font-size: $fontMd;
    }
  }

  .cardWrapper {
    margin-top: $padding;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: $padding;

    .menuCards {
      @include flexCol;
      gap: $padding;
    }

    .accordions {
      @include flexCol;
      gap: $padding;
      width: 100%;
      .notesWrapper {
        height: 70px;
        padding: 0 $padding;
        &.isHero {
          height: 350px;
          padding: 0;
        }
      }
    }
  }
}
