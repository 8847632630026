@import "@/styles/variables.scss";

.DateTimeSplitInput {
  @include flex;
  gap: $spaceSm;

  .column {
    @include flexCol;
    gap: $spaceSm;
  }
}
