@import "@/styles/variables.scss";

.PatientHeader {
  @include flex;
  align-items: center;
  justify-content: space-between;
  gap: $padding;

  background-color: $white;
  height: 75px;
  font-size: $fontBase;
  color: $medGrey;
  transition: transform 750ms ease;

  &.collapsed {
    transform: translateY(-120%)
  }

  div {
    @include flex;
    @include center;
    gap: $padding;
    width: fit-content;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $gray400;
  }

  .name {
    font-weight: $fontSemibold;
  }

  p {
    color: $gray500;
  }

  .age {
    font-weight: $fontSemibold;
    color: $highlightTextBlue;
  }

  .profilePicture {
    cursor: pointer;
  }

  @media print {
    display: none;
  }
}
