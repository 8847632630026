@import "@/styles/variables.scss";

.FileAnnotationReadOnly {
  @include flexCol;
  gap: $padding/2;
  background-color: $grey200;
  padding: $padding/2;
  border-radius: $borderRadius;

  .metadata {
    @include flex;
    align-items: center;
    gap: $padding/2;
  }
  .content {
    background-color: $gray300;
    padding: $padding/4 $padding/2;
    border-radius: $borderRadius;
    p {
      font-size: $fontSmall !important;
    }
  }
}
