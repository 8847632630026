@import "@/styles/variables.scss";

.EmptyState {
  @include card;
  @include flexCol;
  @include center;
  position: relative;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  border: none;
  background-color: $primary25;
  color: $gray400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: $padding;
  &.noMargin {
    margin-bottom: 0;
  }
  .icon {
    margin: $padding;
    &:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      transform: translate(-5px, -5px);
      background-color: $gray500;
      border-radius: 50%;
      opacity: 10%;
    }
    &:after {
      content: "";
      position: absolute;
      width: 45px;
      height: 45px;
      transform: translate(-32px, -12px);
      background-color: $gray400;
      border-radius: 50%;
      opacity: 20%;
    }
  }
}
