@import "@/styles/variables.scss";

.AnimatedSidebar {
  overflow: hidden;

  .innerContent {
    @include flexCol;
    gap: $padding;
    padding: $padding;
    position: relative;
    width: 240px;
    height: 100%;
    padding: $padding;
    overflow-y: auto;
    background-color: $gray50;
    border-radius: $borderRadius;
  }
}
