@import "@/styles/variables.scss";

.Certification {
    @include card;
    border: $gridBorder;
    @include flex;
    align-items: center;
    width: 100%;
    padding: $padding;
    gap: $padding / 2;
    :last-child {
        label {
            display: none;
        }
        width: 15px;
        margin-left: auto;
        flex-shrink: 0;
    }

    @media print {
        display: none;
    }
}
