@import "@/styles/variables.scss";

.pregnancyActions {
  display: flex;
  justify-content: space-between;
  padding-right: $padding;

  &:only-child {
    justify-content: right;
  }

  .action {
    flex: 0 0 50%;
    text-align: center;
  }
}

.noteModal {
  .textAreaContainer {
    border: solid 1px $borderGrey;
    border-radius: $borderRadius;
    padding: 5px 12px;
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    position: relative;

    min-width: 400px;
    min-height: 400px;
  }

  .textAreaContent {
    overflow: auto;
    font-family: inherit;
    line-height: 24px;
  }
}
