@import "@/styles/variables.scss";

.Wizard {
    padding: 0 $padding * 1.5 0 $padding * 1.5;
    @include flexCol;
    .stepMenu {
        @include flex;
        justify-content: space-between;
        margin: $padding/2 0;
        gap: $padding * 2;

        .stepLabel {
            @include flexCol;
            @include center;
            gap: $padding/2;
            p {
                color: $gray400;
            }
            .badge {
                display: flex;
                width: 32px;
                height: 32px;
                @include center;
                border-radius: 50%;
                background-color: $primary50;
                color: $primary600;
                font-weight: $fontSemibold;
                border: 1px solid transparent;
                transition: all 200ms ease;

            }
            &.selected {
                p {
                    color: $primary600;
                }
                .badge {
                    background-color: $blue100;
                    border: 1px solid $primary600;
                }
            }
        }
    }
    .step{
        @include flexCol;
        gap: $padding/2;
        flex: 1;
        padding: $padding/2 0;

        .stepTitle {
            text-align: center;
            width: 100%;
            background-color: $blue100;
            font-weight: $fontSemibold;
            padding: $padding/2 0;
            color: $primary700;
        }
        .stepContent {
            @include flexCol;
            gap: $padding;
            flex: 1;
        }
    }
    .actions {
        @include flex;
        justify-content: space-between;
        margin-top: $padding;
    }
}

.TestContainer {
    @include flexCol;
    gap: $padding/2;
    .TestDetail {
        @include flexCol;
        width: 100%;
        color: $primary700;
        font-size: $fontSmall;
        align-self: stretch;
        border-radius: 7px;
        border: 0.875px solid $primary50;
        background: $primary50;
        box-shadow: 0px 0px 0px 3.5px #E0EAF1, 0px 0.875px 1.75px 0px rgba(16, 24, 40, 0.05);

        .testName {
            @include flex;
            flex: 1;
            justify-content: space-between;
            align-self: stretch;
            padding: 10px 12px;

        }

        .testOptions {
            display: none;
            &.open {
                @include flexCol;
                gap: $padding/2;
            }
            background-color: $white;
            padding: 10px 12px;
            .testOption {
                @include flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}


.orderForm {
    @include flexCol;
    gap: $padding/2;

    .orderEntryDetail {
        @include flexCol;
        gap: $padding/4;
    }
}


.orderDetail {
    @include flexCol;
    .label {
        border-bottom: $gridBorder;
        margin-bottom: $padding/2;
        &:not(:first-of-type) {
            margin-top: $padding/2;
        }
        padding-bottom: $padding/4;
    }

    .selectedTests {
        @include flex;
        gap: $padding/2;
    }
}
