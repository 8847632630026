@import "@/styles/variables.scss";

.AddPatientToConversation {
  @include flexCol;
  gap: $padding;
  height: 300px;
  width: 500px;

  .message {
    font-size: $fontSmall;
    color: $gray500;
  }
  .group {
    @include flexCol;
    gap: $padding/4;
  }
  .label {
    @include textXsMedium;
    color: $gray700;
  }
  .actions {
    margin-top: auto;
    @include flex;
    justify-content: flex-end;
    gap: $padding;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 90%;
    .actions {
      margin-top: auto;
      @include flexCol;
      justify-content: flex-end;
      gap: $padding;
      button {
        width: 100%;
      }
    }
  }
}
