@import "@/styles/variables.scss";

.FileExplorer {
  .header {
    display: flex;
    align-items: center;
    padding: 0 $spaceSm;
    margin-top: $spaceSm;

    .tagsContainer {
      @include flex;
      align-items: center;
      gap: $padding;
      background-color: $gray50;
      width: fit-content;
      padding-left: $padding;
      color: $gray500;
      border-radius: $borderRadius;

      .tagTitle {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .currentTags {
        @include flex;
        gap: $padding / 2;
      }
      .verticalRule {
        border-left: 1px solid $gray300;
        height: 36px;
      }
    }

    .separator {
      margin: 0 1px;
    }

    .breadcrumb {
      cursor: pointer;
    }

    .buttons {
      display: flex;
      margin-left: auto;

      button {
        margin: 5px;
      }
    }
  }

  .explorer {
    display: flex;

    .fileTree {
      width: auto;
      padding: 10px;
    }

    .content {
      width: 100%;
      padding: 10px;
      margin-right: 5px;

      .FileUploader {
        margin-bottom: 10px;
      }
    }
  }
}

.FileUploader {
  border: $gridBorder;
  border-radius: $borderRadius;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 150ms ease;

  &.isDragging {
    background: $gray25;
  }
}

.FileTree {
  .node {
    font-family: monospace;

    .row {
      display: grid;
      grid-template-columns: min-content auto;
      gap: 0.5rem;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
        padding: 2px;
        border-radius: 3px;

        &.selected {
          background-color: #e6f7ff;
        }

        .prefix {
          width: 1em;
          display: inline-block;
        }

        .name {
          margin-left: 0.5em;
          font-family: sans-serif;
        }
      }
    }
  }
}

.FileViewer {
  height: 90vh;

  .header {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding / 2;
    height: 40px;
    margin-bottom: $padding / 2;
    padding: 0;
    padding-left: $padding/2;

    .backIcon {
      cursor: pointer;
    }

    .fileName {
      display: flex;
      align-items: center;
      gap: $padding;
      .filepath {
        display: flex;
        align-items: flex-end;
        gap: $padding;
      }
    }
  }

  .contents {
    display: flex;
    height: 93%;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 80vh;
    }
  }
}

.FileRenderer {
  height: 100%;
  @include flex;
  @include center;
  overflow: hidden;
  position: relative;
}

.imageContainer,
.pdfContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.image,
.pdf {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  width: 100%;
  height: 100%;

  &.loaded {
    opacity: 1;
  }
}

.image {
  object-fit: contain;
}

.loadingPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray25;
  z-index: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid $primary300;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.unsupported {
  padding: 2rem;
  text-align: center;
  color: $gray100;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
