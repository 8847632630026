@import "@/styles/variables.scss";

.recordingContainer {
  display: flex;
  flex-direction: column;
  gap: $padding;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: $padding;
  margin-bottom: $padding;
  margin-right: 10px;
  align-items: center;

  .stopIcon,
  .playIcon {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: $white;
      height: 10px;
      width: 10px;
      fill: $white;
    }
  }

  .stopIcon {
    background: $error400;
    animation: 1s linear infinite alternate recordingPulse;
  }

  .playIcon {
    background: $parioBlue;
  }

  @keyframes recordingPulse {
    0% {
      box-shadow: 0px 0px 0px 0px $error200;
    }

    100% {
      box-shadow: 0px 0px 2px 3px $error200;
    }
  }

  .loadingIcon {
    animation: spin 4s linear infinite;
    stroke: $parioBlue;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.transcriptRenderer {
  border: solid 1px $borderGrey;
  border-radius: $borderRadius;
  min-height: 10rem;
  background: $gray100;
  padding: $padding;
  max-height: 15rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    color: $parioBlue;
    width: 10px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background: $gray100;
    border-radius: $borderRadius;
  }

  &::-webkit-scrollbar-thumb {
    background: $parioBlue !important;
    visibility: visible;
    display: block !important;
    border-radius: $borderRadius;
  }

  .transcriptEntry {
    span {
      color: $gray400;
    }
  }
}
