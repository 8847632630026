@import "../../styles/variables.scss";

.BirthSummary,
.LaborSummaryGrid {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $padding;

  .summaryHeader {
    display: flex;
    align-items: center;
    padding-top: $padding;
    padding-bottom: $padding;

    h3 {
      margin-right: auto;
      margin-left: $padding;
    }

    p {
      margin-right: 10px;

      span {
        font-weight: bold;
      }
    }
  }

  .sectionInfo {
    @include flex;
    padding: 10px;

    .colLeft {
      flex: 1;
      margin-right: auto;
    }

    .colRight {
      flex: 1;
    }
  }
}

.ReadOnlySection {
  font-size: $fontBase;

  &.print {
    .sectionHeader {
      border-bottom: solid 1px black;
    }
  }

  &:not(.print) {
    .sectionHeader {
      @include blueHeader;
    }
  }

  .sectionBody {
    padding: 15px;
    display: flex;
    flex-direction: column;

    .sectionRow {
      display: flex;

      .sectionCol {
        flex: 1;
      }
    }
  }

  // Base element styles within Read Only Components
  ul {
    list-style: none;
    padding-left: 0;
  }

  hr {
    border-top: solid 1px $borderGrey;
    margin-bottom: 10px;
  }

  // One-off styles
  .checkboxGrid {
    display: flex;
    gap: 20px;
  }
}

.KeyValueGrid {
  display: grid;
  grid-auto-flow: row;
  padding: $padding;

  &.twoColumn {
    grid-template-columns: 1fr 1fr;
    gap: $padding;

    .KeyValueItem {
      margin-bottom: 0px;
    }
  }

  &.matchInputHeight {
    .KeyValueItem {
      min-height: 36px;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  .KeyValueItem {
    margin-bottom: 16px;
    display: grid;
    background: $gray100;
    grid-template-columns: [key] 4fr [value] 4fr;
    padding: $padding / 2 $padding;
    border-radius: $borderRadius;

    .gridKey {
      grid-column: key;
      color: $gray500;
    }

    .gridValue {
      grid-column: value;
      margin-left: 10px;
      color: $gray700;

      ul {
        list-style: none;
        padding-right: 0;

        li {
          font-size: $fontBase;
          padding: $padding/4;
        }
      }
    }

    &.longData {
      grid-template-columns: [key] 2fr [value] 6fr;

      .gridValue {
        text-align: right;
      }
    }

    &.textLeft {
      .gridValue {
        text-align: left;
      }
    }
  }

  &.horizontal {
    display: flex;
    gap: $padding;

    .KeyValueItem {
      flex: 1;
      margin-bottom: 0px;
      grid-template-columns: [key] 4fr [value] 9fr;
    }
  }

  .ValueItem {
    margin-bottom: 16px;
    display: grid;
    background: $gray100;
    padding: $padding / 2 $padding;
    border-radius: $borderRadius;

    .singleValue {
      margin: 0;
    }
  }
}

.ProgressGrids {
  flex: 1;
  display: flex;
  flex-direction: column;

  .gridGroup {
    display: flex;
  }
}

.TableItem {
  flex: 1;
  margin-bottom: 10px;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  thead {
    background: $gray100;

    tr {
      th:first-child {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
      }
      th:last-child {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }

      th {
        color: $gray500;
        text-align: left;
        text-transform: capitalize;
        font-size: $fontBase;
        font-weight: $fontReg;
        padding: $padding / 2 $padding;
        vertical-align: top;
        min-width: 50px;

        p {
          margin-right: 20px;
          font-size: $fontBase;
          border-bottom: solid 1px $borderGrey;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        min-width: 50px;
        vertical-align: top;
        font-size: $fontBase;
        padding: $padding / 2 $padding;
      }
    }
  }

  &.tightDisplay {
    margin-bottom: 0;

    td,
    th {
      padding: 0 $padding / 2;
    }
  }
}

.LaborSummaryGrid {
  th p {
    font-size: $fontSmall;
  }

  td p {
    font-size: $fontSmall;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $padding;
  margin-top: $padding;
}

.cert {
  margin-top: $padding;
}

.ReadOnlySection.print {
  p {
    border-color: black;
  }

  .sectionBody {
    padding: 0;
  }

  .sectionRow {
    gap: $padding / 2;
  }

  .KeyValueGrid {
    padding: 0;

    .KeyValueItem {
      background: $white;
      padding: 0;
      border-bottom: solid 1px black;
      border-radius: 0;
      margin-bottom: 0;
      margin-top: $padding;

      .gridKey {
        color: black;
      }

      .gridValue {
        color: black;
      }
    }

    .ValueItem {
      margin-top: $padding;
      background: $white;
      padding: 0;
    }
  }

  .TableItem {
    border-spacing: $padding / 2;

    thead {
      background: $white;

      th {
        padding: 0;
        font-weight: $fontSemibold;
      }
    }
    td {
      padding: 0;
      text-align: left;

      &.border {
        border-bottom: solid 1px black;
      }
    }
  }
}
