@import "@/styles/variables.scss";

.FixedMenu {
  position: absolute;
  width: 100%;
  padding: 10px $padding;
  border-radius: $borderRadius;
  background-color: $white;
  border: $gridBorder;
  box-shadow: $shadowLg;
  z-index: 10;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
  &.fitContent {
    width: fit-content;
  }
  ul {
    list-style: none;
    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      button {
        @include flex;
        align-items: center;
        gap: $padding;
      }
      span {
        &::first-letter {
          text-transform: capitalize;
        }
        font-size: $fontSmall;
      }
    }
  }
}
