@import "@/styles/variables.scss";

.ViewBody {
  background-color: white;

  .menu {
    @include flex;
    gap: $padding;
    padding: $padding;
    justify-content: flex-end;
  }
  .content {
    @include flex;
  }

  .icon {
    button {
      background-color: $primary50;
    }
  }

  .contentViewer {
    height: calc(100vh - 141px);
    width: 100%;
  }
}
