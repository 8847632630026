@import "@/styles/variables.scss";

.FileList {
  @include flexCol;
  gap: $padding;

  .fileWrapper {
    $padding: $padding;
  }
  .actionBtn {
    padding: $padding/2;
    width: 90%;
    margin-left: 5%;
    font-weight: $fontSemibold;
    cursor: pointer;
    @include roundBottom;
    &.add {
      background-color: $success;
      color: $successText;
    }
    &.remove {
      background-color: $error;
      color: $errorText;
    }
  }
}
