@import "@/styles/variables.scss";

.InactivityModal {
  p {
    margin: $padding;
  }
}

.TokenExpiryModal {
  p {
    margin: $padding;
  }

  .buttons {
    @include flex;
    justify-content: space-between;

    button {
      @include flex;
      @include center;
      height: 36px;
      border-radius: $borderRadius;
      padding: 0 12px;
      padding-bottom: 1px; // centering text w/ line-height
      font-size: $fontBase;
      font-weight: $fontSemibold;
      background-color: $primary500;
      color: $white;
      flex-shrink: 0;
      width: fit-content;
      gap: 8px;
      text-decoration: none;
      cursor: pointer;
      border: 1px solid $primary400;
      transition: background-color 150ms ease;
      position: relative;
      text-wrap: nowrap;

      &.logout {
        background-color: $error50;
        border-color: $error300;
        color: $errorText;
      }
    }
  }
}
