@import "@/styles/variables.scss";

.OptionTile {
  @include flex;
  justify-content: space-between;
  gap: $padding/2;
  border: $gridBorder;
  border-radius: $borderRadius;
  padding: $padding/2 $padding/1.5;
  transition: all 0.2s ease-in-out;

  &.selected {
    border-color: $primary600;
    background-color: $primary50;
    box-shadow: 0px 0px 0px 4px $primary100;
  }

  .content {
    @include flexCol;
    gap: $padding/4;
    text-align: left;

    .description {
      color: $gray700;
      @include textXsReg;
    }
  }

  .title {
    @include flex;
    align-items: flex-start;
    gap: $padding/2;
    color: $primary900;
    @include textLgSemibold;
    font-size: 18px;
  }

  @media (max-width: 767px) {
    padding: $padding/4 $padding/3;

    .title {
      @include flex;
      align-items: center;
      gap: $padding/2;
      color: $gray500;
      font-size: $fontBase;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .content {
      gap: 0px;
      .description {
        display: none;
      }
    }
    &.selected {
      .content {
        .description {
          display: block;
        }
      }
      .title {
        color: $primary900;
      }
    }
  }
}
