@import "@/styles/variables.scss";

.LaborFlowSheet {
    @include card;
    background-color: $gray50;
    .header {
        display: flex;
        justify-content: space-between;
        padding: $padding/4 $padding/2;
        gap: $padding;

        div {
            display: flex;
            @include center;
        }

        h3 {
            margin-left: $padding/2;
        }
    }

    .emptyState {
        @include flexCol;
        background-color: $white;
        @include center;
        padding: $padding * 2;
        gap: $padding/2;
        p {
            max-width: 360px;
            text-align: center;
        }
    }

    .content {
        display: grid;
        table {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            border: none;
            border-top: $gridBorder;

            tr {
               min-width: 300px;
            }

            &.EVENT {
                background-color: $success25;
            }

            &.MEDICATION, &.INFANT_MEDICATION{
                background-color: $yellow50;
            }

            &.PROGRESS_UPDATE, &.INFANT_PROGRESS_UPDATE {
                background-color: $gray50;
            }

            &:last-child {
                border-bottom-left-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }
        }

        td, th {
            text-align: left;
            font-weight: $fontReg;
            text-transform: capitalize;
            vertical-align: top;
            width: 100%;
            height: 100%;
            button {
                color: $gray700;
                text-align: left;
            }

            &.action, &.infantBadge {
              text-align: right;
            }

            &.infantBadge {
                font-size: $fontSmall;
                span {
                    background-color: $blue100;
                    font-size: 10px;
                    color: $parioBlue;
                    padding: $padding/8 $padding/4;
                    border-radius: $borderRadius/2;
                }

            }

            &:first-child {
                padding-left: $padding/2;
            }
            &:last-child {
                padding-right: $padding/2;
            }
        }

        th {
            padding: $padding/4;
            padding-bottom: 0;
            padding-top: $padding/2;
            color: $gray500;
            font-size: $fontSmall;
            text-align: left;

            button {
                color: $gray500;
                text-transform: capitalize;
                text-align: left;
                font-size: $fontSmall;
            }
        }

        td {
            padding: $padding/4;
            padding-top: 0;
            padding-bottom: $padding/2;
            color: $gray700;
            font-size: $fontBase;

            &.spanRow {
                width: 100%;
                font-size: $fontSmall;
            }
        }

        /* Set a minimum width for each column */
        td:nth-child(1), th:nth-child(1) {
        min-width: 100px; /* Adjust the minimum width as needed */
        }

        td:nth-child(2), th:nth-child(2) {
        min-width: 150px; /* Adjust the minimum width as needed */
        }

        tfoot > button > p {
            font-size: $fontSmall;
            padding: $padding /2;
            color: $errorText;
        }

        tfoot {
            display: flex;
        }

    }
}
