@import "../../styles/variables.scss";

@mixin gridStyles {
  width: 100%;
  font-size: $fontBase;
  color: $lightGrey;
  background-color: $white;
  border-radius: $borderRadius;
}

.Datagrid {
  @include gridStyles;
  border-spacing: 0;
  box-shadow: $shadowXs;
  table-layout: fixed;
  border-collapse: collapse;

  &.unfixed {
    table-layout: auto;
  }

  &.topAligned {
    tr {
      td {
        vertical-align: top;
      }
    }
  }

  thead {
    background-color: $primary25;
    font-size: $fontSmall;
    tr {
      th {
        text-align: left;
        text-transform: capitalize;
        padding: $padding/2;
        min-width: 150px;
        @include textXsMedium;
        // round top corners of table

        &.highlight {
          button {
            font-weight: $fontBold;
          }
        }

        &.narrow {
          width: 50px;
        }

        button {
          width: 100%;
          height: 100%;
          color: $lightGrey;
          text-align: left;
          text-transform: capitalize;
          font-size: $fontSmall;

          span {
            margin-left: 4px;
          }
        }
      }
    }
  }

  tbody {
    font-weight: $fontReg;
    tr {
      position: relative;
      &.clickable {
        cursor: pointer;
      }
      background-color: $white;
      transition: background-color 150ms ease;

      td {
        border-top: $gridBorder;
        padding: $padding / 2;
        word-wrap: break-word;

        &.inputCell {
          padding: $padding/4;

          button {
            max-width: unset;
          }
        }

        .metricDisplay {
          padding: $padding/4 $padding/2;
          background-color: $gray100;
          border-radius: $borderRadius;
        }
        // special styling for td that has two elements within it
        // Apply ellipsis to text in a specific column or class
        .name,
        .note {
          display: flex;
          align-items: center;
          gap: $padding;
          white-space: nowrap;
          // Add ellipsis to text within these elements
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .labNote {
          overflow-x: scroll;

          p {
            text-wrap: nowrap;
            font-family: monospace;
            font-weight: $fontReg;
          }
        }

        &.drawer {
          background-color: $gray50;
          text-align: left;
        }

        // highlight second column
        :nth-child(2) {
          font-weight: $fontSemibold;
          line-height: $spaceMd;
        }
      }

      // round bottom corners of table
      &:last-child {
        td {
          &:first-of-type {
            border-bottom-left-radius: $borderRadius;
          }

          &:last-of-type {
            border-bottom-right-radius: $borderRadius;
          }
        }
      }

      &:hover {
        background-color: $highlightBlue;

        .actions {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  &.secondary {
    border: none;

    thead {
      border-radius: 0;

      tr {
        th {
          border-radius: 0;
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: $fontBase;
          font-weight: $fontReg;
          color: $darkGrey;
          span:not(:first-child) {
            margin-left: $padding;
          }
        }
      }
    }
  }

  .collapse {
    width: 0px;
    min-width: 0px;
    text-align: right;
    border-bottom: $gridBorder;
  }

  .checkboxHeader {
    width: 32px;
    min-width: 32px;
  }

  .actions {
    button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .dateTime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .time {
      font-size: $fontSmall;
      color: $gray400;
    }
  }
}

.DatagridActions {
  @include flex;
  justify-content: flex-end;
  gap: $spaceMd;
  @include gridStyles;
  padding: $spaceSm $spaceMd;
  max-width: 300px;

  :first-child {
    justify-self: flex-start;
  }
}

.DatagridFilter {
  @include flex;
  align-items: center;
  justify-content: space-between;
  gap: $spaceMd;
  background-color: $gray50;
  padding: $padding $padding * 1.5;

  .actionsWrapper {
    @include flex;
    width: fit-content;
    margin-left: auto;
    border: $gridBorder;
    border-radius: $borderRadius;

    button {
      padding: 10px $padding;
      @include flex;
      @include center;
      width: 140px;
      white-space: nowrap;
      gap: $padding / 2;
      background-color: transparent;
      color: $gray500;
      border-left: $gridBorder;
      border-right: $gridBorder;

      &.save {
        justify-content: flex-end;
      }
      &.selected {
        background-color: $white;
        box-shadow: $shadowMd;
        color: $gray700;
      }

      &:first-child {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        border: none;
      }
      &:last-child {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
        border: none;
      }
    }
  }

  :first-child {
    max-width: 300px;
  }
}

.ActionGrid {
  @include flexCol;
  gap: $spaceMd;
  .content {
    padding: 0 $padding * 1.5;
  }
}

.PrenatalFlowSheet.readOnly {
  width: 100%;
  * {
    box-shadow: none;
    background: #fff;
  }
}
