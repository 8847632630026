@import "@/styles/variables.scss";

@mixin gridStyles {
  width: 100%;
  font-size: $fontBase;
  color: $lightGrey;
  background-color: $white;
  border-radius: $borderRadius;
}

.GeneratedForm,
.GeneratedFormSection {
  form {
    @include flexCol;
    gap: 1.5 * $padding;

    fieldset {
      @include flexCol;
      gap: 1.5 * $padding;
      padding: $padding * 1.5;
      border: $gridBorder;
      border-top: 0;
      @include roundBottom;
      legend {
        // leave for accessibility, but hide visually
        @include visuallyHidden;
      }
    }

    .footer,
    .header {
      @include flex;
      justify-content: space-between;
      padding: $padding;
    }

    .footer {
      margin: $padding 0;
      @include roundBottom;
      background-color: $gray50;
    }

    .buttons {
      margin: $padding 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $padding;
    }

    .header {
      @include roundTop;
      margin-bottom: $padding;
      font-weight: $fontSemibold;
      color: $parioBlue;
      font-size: $fontMd;
    }
  }

  &.GeneratedFormSection {
    fieldset {
      border: none;
    }

    .footer {
      margin-top: 0;
    }
  }

  table {
    @include gridStyles;
    border: $gridBorder;
    border-spacing: 0;
    box-shadow: $shadowXs;

    thead {
      font-size: $fontSmall;
      background-color: $gray50;

      tr {
        th {
          text-align: left;
          padding: $padding/2;
          font-weight: $fontSemibold;
          vertical-align: bottom;

          // round top corners of table
          &:first-of-type {
            border-top-left-radius: 6px;
          }

          &:last-of-type {
            border-top-right-radius: 6px;
          }

          &.highlight {
            button {
              font-weight: $fontBold;
            }
          }

          button {
            width: 100%;
            height: 100%;
            color: $lightGrey;
            text-align: left;
            text-transform: capitalize;
            font-size: $fontSmall;

            span {
              margin-left: 4px;
            }
          }
        }
      }
    }

    tbody {
      font-weight: $fontReg;

      tr {
        &.clickable {
          cursor: pointer;
        }
        background-color: $white;
        transition: background-color 150ms ease;

        td {
          border-top: $gridBorder;
          padding: $padding/2 $padding/4;

          &:first-child {
            font-weight: $fontSemibold;
            color: $darkGrey;
          }

          &.inputCell {
            button {
              max-width: unset;
            }
          }

          // special styling for td that has two elements within it
          .name,
          .note {
            display: flex;
            align-items: center;
            gap: $padding;
            white-space: nowrap;
          }

          &.drawer {
            background-color: $gray50;
          }

          // highlight second column
          :nth-child(2) {
            font-weight: $fontSemibold;
            line-height: $spaceMd;
          }
        }

        // round bottom corners of table
        &:last-child {
          td {
            &:first-of-type {
              border-bottom-left-radius: $borderRadius;
            }

            &:last-of-type {
              border-bottom-right-radius: $borderRadius;
            }
          }
        }

        &:hover {
          background-color: $highlightBlue;
        }
      }
    }
  }

  tfoot {
    border-top: $gridBorder;

    button {
      @include flex;
      @include center;
      padding: $padding/2;
      border-top: $gridBorder;
      color: $gray600;
    }
  }
}
