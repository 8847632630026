@import "../../styles/variables.scss";

.Create {
  @include flexCol;
  gap: $padding/4;

  .buttonWrapper {
    @include flex;
    gap: $spaceMd;
    margin-top: 20px;
  }
}

.loading {
  @include flexCol;
  @include center;
  min-width: 550px;
  max-height: 90vh;
  overflow-y: auto;
  gap: 20px;
}

.CreateAppointmentForm {
  @include flexCol;
  width: 40em;
  max-height: 90vh;
  overflow-y: auto;
  gap: 20px;

  .header {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;

    .actions {
      display: flex;
      gap: $padding;
      position: absolute;
      justify-content: center;
      top: $padding;
      align-items: center;

      // select the 1st div in the actions div
      & > div:first-child {
        width: 25em;
      }
    }
  }

  .actionHeader {
    @include flex;
    justify-content: space-between;
    min-width: 540px;
    white-space: nowrap;
    min-height: 44px;
  }

  .content {
    width: 100%;
    padding: $padding;
  }

  .grid {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: $padding/2;
    padding: $padding;

    .row {
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: $padding;

      &:not(.isEditMode) {
        padding: $padding/2;
        border-radius: $borderRadius;
        background-color: $gray50;
      }
    }

    .patientStatus {
      display: flex;
      width: fit-content;
      grid-column: 1/3;
      gap: $padding / 2;
    }
  }

  .tagContainer {
    @include flex;
    gap: $padding/2;
    padding: $padding/2 0;
  }
  .buttons {
    margin-top: 100px;
    margin-bottom: 10px;
  }
}

.PatientGeneralInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding;
  padding: $padding;
  max-width: 100vw;
  @media (max-width: 768px) {
    @include flexCol;
    gap: $padding/2;
    padding: $padding/4;
  }

  button {
    grid-column: span 2;
  }
}

.InsuranceForm {
  padding: $padding;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    button {
      margin-top: $padding/2;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding;
    padding: $padding;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 768px) {
      @include flexCol;
      gap: $padding/2;
      padding: 0;
    }

    .payorWrapper {
      p {
        text-align: center;
        margin: $padding / 2 0;
        color: $gray400;
        font-weight: $fontBold;
        text-transform: uppercase;
      }

      button {
        width: 100%;
      }
    }
  }

  .buttonContainer {
    bottom: -120px;
    transition: all 0.25s;

    .warningText {
      background: $error;
      border-radius: $borderRadius;
      padding: 0 $padding / 2;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: $errorText;
    }
  }

  .floatingButton {
    position: sticky;
    bottom: -56px;
    box-shadow: $shadowLg;

    button {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}

@media (max-width: 767px) {
  .InsuranceForm {
    .wrapper {
      grid-template-columns: 1fr;
    }
  }
}

.CreateUserForm {
  width: 750px;
  .gridCol3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .gridCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .gridCol1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .validationError {
    background-color: $error;
    padding: 0px 4px;
    margin-left: 6px;
    color: $errorText;
    font-size: 10px;
    position: absolute;
  }
}

.PregnancyDatingForm {
  padding: $padding;
  height: 300px;
  min-width: 500px;
  @include flexCol;

  form {
    @include flexCol;
    flex: 1;
    gap: $padding/2;
    .buttons {
      margin-top: auto;
    }
  }
}

.buttonGrid {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: $padding;
}

.checkoutGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding;
  align-items: center;
  padding: $padding;
  .cptRow {
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: $padding;
    align-items: center;
  }
  .chargeRow {
    display: grid;
    grid-template-columns: 4px 2fr 1fr;
    gap: $padding;
    align-items: center;
    justify-items: end;
  }
  .total {
    margin-right: auto;
  }
}
