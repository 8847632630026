@import "@/styles/variables.scss";

.Medications {
  height: 300px;
  padding: $padding;
  overflow-y: auto;

  .MedicationRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding $padding/2;
    border-bottom: $gridBorder;
    gap: $padding/2;
    &:hover {
      cursor: pointer;
    }
    .MedicationName {
      text-align: left;
      flex-grow: 1;
      color: $parioBlue;
      font-weight: $fontSemibold;
      font-size: $fontBase;
      line-height: unset;
      text-transform: capitalize;
    }
  }
}
