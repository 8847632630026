@import "../../styles/variables.scss";

.TimerIndex {
    @include flex;
    @include card;
    justify-content: space-between;

    .timestamp {
        @include flexCol;
        width: 100%;
        padding: $padding/2 $padding;
        ::first-letter {
            text-transform: capitalize;
          }


        &:not(:first-child) {
            border-left: $gridBorder;
        }

        .timeElapsed {
            color: $primary600;
        }
    }

}
