@import "@/styles/variables.scss";

.PatientMetadata {
  @include flexCol;
  border-radius: $borderRadius;
  padding: $padding * 1.5 $padding;
  gap: $padding;
  background-color: transparent;
  width: 100%;
  background-color: $primary600;
  p {
    color: $primary100;
  }

  .content {
    @include flexCol;
    gap: $padding / 2;
  }
}
