@import "@/styles/variables.scss";

.DashboardCard {
  @include card;
  border-top: none;
  width: auto;

  .header {
    @include blueHeader;
    overflow-x: hidden;
    color: $primary700;

    h4 {
      flex-shrink: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .actionsWrapper {
      display: flex;
      gap: $padding;
    }
  }

  .metaWrapper {
    display: flex;
    align-items: center;
    gap: $padding;
  }

  .content {
    overflow: visible;
    &.emptyState {
      display: flex;
      @include center;
      margin: $padding;
    }
  }

  &.readOnly {
    .header {
      background-color: $primary50;
      color: $gray500;
    }
  }
}
