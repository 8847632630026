@import "@/styles/variables.scss";

.ReadGenericStaffTask {
  height: calc(100% - $padding * 2); // Make the outer container full height
  display: flex; // Add this to allow container to expand
  width: 100%;
  min-width: 450px;

  .container {
    @include flexCol;
    gap: $padding;
    flex: 1; // Change height: 100% to flex: 1 to fill available space
    .group {
      @include flexCol;
      gap: $padding/4;
      .patientWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $padding;
      }
    }
    .buttons {
      @include flex;
      justify-content: flex-end;
      gap: $padding/2;
      margin-top: auto; // Push buttons to bottom
    }
  }
}
