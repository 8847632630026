@import "@/styles/variables.scss";

$gridPadding: 12px $padding;

.SuperbillContent {
  @include flexCol;
  gap: $padding;
  width: 100%;
  height: 100%;
  margin-top: $padding * 1.5;

  .contentWrapper {
    @include flexCol;
    gap: $padding;
    margin: 0 1.5 * $padding;
  }

  .headerWrapper {
    @include flex;
    align-items: flex-start;
    li,
    input {
      text-transform: capitalize;
    }
  }

  .header,
  .footer {
    @include flex;
    justify-content: space-between;
    align-items: center;
  }

  .cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $padding;

    .card {
      @include card;
      border: $gridBorder;
      padding: $padding;
      text-transform: capitalize;
      p {
        line-height: 22px;
      }
    }
  }

  .gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    min-width: fit-content;
    @include card;

    &.codings {
      grid-template-columns: 1fr;
    }

    div {
      border-right: $gridBorder;
    }

    table {
      border-spacing: 0;
      background-color: $white;
      border-radius: $borderRadius;
      width: 100%;

      caption {
        background-color: $white;
        padding: $gridPadding;
        text-align: left;
        @include roundTop;
      }

      thead {
        background-color: $parioLightBlue;
        tr {
          th {
            padding: $gridPadding;
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: $gridBorder;
            padding: $gridPadding;
            word-wrap: break-word;
          }
          // round bottom corners of table
          &:last-child {
            td {
              border-bottom: none;
              &:first-of-type {
                border-bottom-left-radius: $borderRadius;
              }

              &:last-of-type {
                border-bottom-right-radius: $borderRadius;
              }
            }
          }
        }
      }
    }
  }

  // PDF print styles
  @media print {
    .statusSelector {
      display: none;
    }
    .gridWrapper {
      // render sections vertically instead of horizontally
      grid-template-columns: 1fr;
      border: none;
      box-shadow: none;
      div {
        border-right: none;
      }
      // adjust table styles to accomodate print layout
      table {
        margin-top: $padding;
        border-radius: 0;
        box-shadow: none;
        @include roundBottom;
        caption {
          border: $gridBorder;
        }
        thead {
          tr {
            th {
              font-weight: 600; // for contrast in print
            }
          }
        }
        th,
        td {
          border-bottom: $gridBorder;
        }
      }
    }

    // add contrast for print
    .cardWrapper {
      .card {
        box-shadow: none;
      }
    }

    // elements to hide on print
    .footer,
    button {
      display: none;
    }
  }
}
