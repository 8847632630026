@import "@/styles/variables.scss";

.blueThemeInput {
  width: 100%;
  font-size: 14px;
  line-height: 23.95px; // tweaks the height of input to exactly match the react-select styles
  border-radius: 8px; // Matches blueThemePalette.borderRadius.medium
  background-color: $primary800; // Matches blueThemePalette.primaryDark
  padding: 6px 10px 4px 10px; // Matches blueThemePalette.spacing.medium
  border: 1px solid $primary500; // Matches blueThemePalette.borderDefault
  color: $primary200; // Matches blueThemePalette.textPrimary

  &:hover,
  &:focus {
    outline: none;
    border-color: $primary200; // Matches blueThemePalette.borderHover
  }

  &::placeholder {
    color: rgba($primary200, 0.5); // 50% opacity, matches placeholder opacity
  }
}
