@import "@/styles/variables.scss";

.PatientID {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  gap: $padding/2;

  .headerData {
    @include flexCol;
    gap: $padding/2;
    align-items: flex-start;
    h4 {
      color: $primary50;
      font-size: $fontSemibold;
      font-weight: $fontSemibold;
    }
    h5 {
      color: $darkGrey;
      font-size: $fontSemibold;
      font-weight: $fontReg;
    }
    .nameNote {
      @include flex;
      align-items: flex-start;
      gap: $padding/2;
    }
    .privateNote {
      margin-top: 3px;
    }
  }
}
