@import "@/styles/variables.scss";

.CreateCaseModal {
  @include flex;
  flex-direction: column;
  gap: $spaceSm;

  .twoColumns {
    @include flex;
    gap: $spaceSm;
    width: 100%;

    .column {
      @include flexCol;
      gap: $spaceSm;
      width: 100%;
    }
  }

  .characterCount {
    color: $lightGrey;
  }

  .buttons {
    @include flex;
    align-items: center;
    justify-content: flex-end;
    gap: $spaceSm;
  }
}
