@import "@/styles/variables.scss";

.PrenatalFlowSheet {
    display: flex;
    flex-direction: column;
    gap: $padding;
}

.PrenatalEntries {
    display: flex;
    padding: $padding;
    gap: $padding / 2;
}
