@import "../../styles/variables.scss";

.Drawer {
  @include card;
  font-weight: $fontSemibold;
  border-radius: 5px 5px 0 0;
  max-width: 400px;
  width: 100%;
  position: fixed;
  right: $spaceLg;
  bottom: 0;
  transition: transform 0.3s ease;
  transform: translateY(calc(100% - 40px)); // show only drawer lip

  .drawerTop {
    @include lip;
    justify-content: space-between;
    padding: $spaceSm $spaceMd;
  }

  .drawerBody {
    visibility: hidden;
    padding: $spaceSm $spaceMd;
  }

  &.expanded {
    transition: transform 0.3s ease;
    transform: translateY(0);

    .drawerBody {
      visibility: visible;
    }
  }
}

.LeftPane {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $primary800;
  width: 300px;
  transform: translateX(calc(-100% - $sidebarWidth));
  padding: $padding;
  transition: transform 1s ease-in-out;
  max-height: 100vh;
  overflow: auto;

  .sticky {
    position: sticky;
    top: 0;
  }

  nav {
    @include flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $padding/2;
    margin-top: -$padding/2;

    button {
      @include flex;
      align-items: center;
      font-weight: $fontSemibold;
      color: $primary600;
      width: fit-content;
      gap: $padding / 2;
    }
  }

  &.isVisible {
    transform: translateX($sidebarWidth);
  }
}

.RightPane {
  height: 100vh;
  width: 100%; // should not fully overlap container when expanded
  position: fixed;
  right: 0;
  display: flex;
  transform: translateX(100%);
  transition: transform 500ms ease;
  z-index: 4;

  // accessibility
  visibility: hidden;

  &.isVisible {
    transform: translateX(0);
    // accessibility
    visibility: visible;
    .background {
      opacity: 0.5;
      transition: opacity 400ms ease 400ms;
    }
  }

  .background {
    width: 100%;
    background-color: $lightGrey;
    opacity: 0;
  }

  .wrapper {
    width: fit-content;
    background-color: $gray25;
    padding: $padding;

    .header {
      @include flex;
      align-content: center;
      justify-content: flex-end;
      padding-bottom: $padding;
    }

    .content {
      height: 100%;
      padding: 0 $padding $padding $padding/2;
      overflow: auto;
    }
  }
}
