@import "@/styles/variables.scss";

.FileAnnotations {
  .annotationWrapper {
    @include flexCol;
    gap: $padding;
    align-items: center;
    padding: $padding;
    min-width: 400px;
    max-height: 800px;

    .overflow {
      @include flexCol;
      gap: $padding;
      align-items: center;
      padding: $padding;
      overflow-y: scroll;
      min-width: 400px;
    }

    .addWrapper {
      @include flex;
      padding-bottom: $padding / 2;
      border-bottom: $gridBorder;
      position: relative;
      .addAnnotation {
        color: $gray400;
        font-size: $fontLg;
        width: 100%;
        transition: all 150ms ease;
        &:hover {
          color: $white;
          background-color: $parioBlue;
        }
      }
    }

    .emptyState {
      @include flexCol;
      @include center;
      height: 100%;
      text-align: center;
      gap: $padding;
      height: 180px;

      .addAnnotation {
        color: $gray400;
        font-size: $fontLg;
        border: 1px solid $gray300;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin: auto;
      }
    }
  }
}
