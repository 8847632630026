@import "../../styles/variables.scss";

.NotificationLink {
  background: $warning;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    padding: 2px 2px;
    min-width: 14px;
    transform: translate(25%, -25%);

    span {
      font-size: 10px;
      line-height: 11px;
    }
  }

  span {
    font-size: $fontSmall;
    color: $warningText;
    font-weight: 600;
  }
}
