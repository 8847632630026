@import "@/styles/variables.scss";

.FileNavigation {
  @include flexCol;
  @include center;
  gap: $padding;
  // adjustments to position w/in parent
  margin-top: -$padding;
  .navHeader {
    width: 240px;
    margin-bottom: -$padding/2;
    // end adjustments
    display: flex;
    align-items: flex-end;
    gap: $padding/2;
    background-color: $primary500;
    padding: $padding/2 $padding;
    border-radius: $borderRadius/2;
    font-size: $fontBase;
    font-weight: $fontSemibold;
    color: $gray100;
    &.secondary {
      background-color: $primary200;
      color: $gray600;
    }
  }
  .fileListWrapper {
    padding: 0 $padding;
  }
}
