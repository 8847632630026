@import "@/styles/variables.scss";

.Annotation {
  @include flexCol;
  gap: $padding / 2;
  width: 350px;
  background-color: $parioLightBlue;
  padding: $padding;
  border-radius: $borderRadius;
  border: $gridBorder;

  .metaWrapper {
    @include flex;
    gap: $padding/2;
  }

  .content {
    padding: $padding/2;
    flex: 1;
  }

  .buttons {
    gap: $padding/2;
    display: flex;
    margin-left: auto;
  }
}

.AddAnnotation {
  &:not(.edit) {
    position: absolute;
    top: 38px;
    left: -60px;
    z-index: 1;
    min-width: 350px;
    padding: $padding;
  }
  width: 100%;
  text-align: left;
  @include flexCol;
  align-items: flex-end;
  gap: $padding;
  background-color: $parioLightBlue;
  border-radius: $borderRadius;
  .textAreaBorder {
    background-color: white;
  }
}
