@import "../../styles/variables.scss";

.Pagination {
  @include flex;
  @include center;
  padding: $padding $padding/2;

  button.paginationNav {
    @include card;
    padding: $spaceSm $spaceMd;
    color: $lightGrey;
    font-weight: $fontSemibold;
    border: $gridBorder;

    &.disabled {
      border-color: transparent;
    }
  }

  .pageMenu {
    @include flex;
    @include center;
    gap: $spaceSm;
    padding: 0 $padding * 1.5;

    .pageButton {
      padding: $spaceSm $padding;
      background-color: transparent;
      color: $lightGrey;
      border-radius: 3px;
      font-weight: $fontSemibold;
      flex-shrink: 0;

      &.selected {
        background-color: $inputGrey;
        color: $medGrey;
      }
    }
  }

  &.minimal {
    padding: 0;
    .pageButton {
      padding: 0;
    }
    .pageMenu {
      @include flex;
      @include center;
      gap: $padding/2;
      padding: 0;
    }
    .pageButton {
      padding: $padding/4 $padding/2;
      background-color: transparent;
      color: $gray400;
      font-weight: $fontSemibold;
      font-size: $fontSmall;
      flex-shrink: 0;

      &.selected {
        background-color: $inputGrey;
        color: $gray500;
      }
    }
  }
}
