@import "@/styles/variables.scss";

.TextArea {
  position: relative;
  @include flexCol;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  &:not(.noBorder) {
    .textAreaBorder {
      border: solid 1px $borderGrey;
      padding: 5px 12px;
      @include roundBottom;
      @include roundTop;
      background-color: white;
    }
  }

  .textAreaContent {
    overflow: auto;
    font-family: inherit;
    line-height: 24px;
  }

  .textAreaLabel {
    display: inline-block;
    @include textSmMedium;
    color: $gray700;
    margin: 4px;
    &.hidden {
      @include visuallyHidden;
    }
  }

  .menuBarContainer {
    display: flex;
    align-items: center;
    overflow-x: hidden;

    &.menuBarGap {
      overflow-x: unset;

      .menuButton {
        margin-right: $padding/4;
      }
    }

    .dotPhraseHint {
      font-size: $fontSmall;
      color: $gray400;
    }

    .menuButton {
      height: 34px;
      width: 34px;
      border-radius: $borderRadius;
      position: relative;
      z-index: 9;
      transition: all 0.1s;
      margin-left: 0;

      &:hover {
        background: $grey200;
      }
    }
  }

  a {
    color: $infoText;
    cursor: pointer;

    &:hover {
      color: $blue700;
    }
  }
}

@media (max-width: $tablet) {
  .MenuBar {
    justify-content: space-between;
  }
  .dotPhraseHint {
    display: none;
  }
}

.MenuBar {
  //margin-top: 10px;
  display: inline-flex;
  gap: 5px;
  max-width: 100%;

  button {
    height: 34px;
  }

  &.noMargin {
    margin-top: 0px;
  }

  .textControl {
    //background-color: $blue100;
    @include roundBottom;
    @include roundTop;
    transition: all 0.15s;
    padding: 0 10px;

    &.active {
      background-color: $grey200;
    }
  }
  .loadingIcon {
    animation: spin 4s linear infinite;
    stroke: #fff;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.LinkPopUp {
  @include roundBottom;
  @include roundTop;
  border: $gridBorder;
  border-width: 2px;
  box-shadow: $shadowMd;
  background: $white;
  position: absolute;
  width: 100%;
  top: -200px;
  padding: $padding / 2;
  visibility: hidden;

  &.open {
    visibility: visible;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    padding-top: $padding / 2;
  }
}
