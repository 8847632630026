@import "@/styles/variables.scss";

.Vitals {
  width: 100%;
  padding: $padding;
  .timestamp {
    margin-left: auto;
    font-size: $fontSmall;
    color: $gray500;
    text-align: right;
    margin-bottom: $padding * 1.5;
  }

  .formType {
    @include flexCol;

    .inputWrapper {
      @include flex;
      gap: $spaceSm;
    }

    .metricDisplay {
      width: 100%;
      .label {
        @include textSmMedium;
        color: $gray700;
        margin: 4px;
      }
      .metricData {
        margin: 4px;
        padding: $padding/2;
        background-color: $gray100;
        border-radius: $borderRadius;
        min-height: 36px;
      }
    }

    .buttonWrapper {
      @include flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $padding * 1.5;
    }
  }
}

.HealthHistory {
  .actionType {
    @include flex;
    align-items: center;
    justify-content: space-between;
    font-size: $fontMd;
    color: $darkGrey;
    font-weight: $fontSemibold;
  }
}

.Physical {
  .actionType {
    @include flex;
    align-items: center;
    justify-content: space-between;
    font-size: $fontMd;
    color: $darkGrey;
    font-weight: $fontSemibold;
  }
}

.AssessmentPlan {
  flex: 1;
  width: 100%;
  @include flexCol;
  gap: $padding;

  .labWork {
    border: $gridBorder;
    border-top: none;
    padding: 0 $padding / 2;
  }

  .labCart {
    @include flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: $padding;
    gap: $padding / 2;
  }

  .labResultsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: $gridBorder;
    padding: $padding;

    div {
      display: flex;
      border-right: $gridBorder;
      justify-content: center;
    }
  }

  .codingGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 60px;
    border-bottom: $gridBorder;

    .cell {
      @include flexCol;
      gap: $padding;
      padding: $padding;
      border-right: $gridBorder;
      border-top: $gridBorder;

      .diagnosis {
        @include flex;
        align-items: flex-start;
        gap: $padding;
      }

      &.first {
        border-left: $gridBorder;
        button {
          height: min-content;
        }
      }

      .customInput {
        @include flex;
        gap: $padding / 2;
        p {
          min-width: max-content;
        }
      }
    }
  }

  .actions {
    @include flex;
    justify-content: flex-end;
    gap: $padding;
    padding: $padding;
  }

  .header {
    @include flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray50;
    color: $gray500;
    font-weight: $fontSemibold;
    padding: $padding;
    @include roundTop;
  }
}

.VisitSummary {
  width: 100%;

  .VisitSummaryItems {
    display: grid;
    flex: 1;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    padding: $padding;
    gap: $spaceXL;
    justify-items: start;

    .summaryItem {
      @include flexCol;
      gap: $padding / 2;

      .name {
        font-weight: bold;
      }

      .value {
        color: $secondary;
        font-weight: normal;
      }
    }
  }
}

.LabOrders {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  div {
    font-size: $fontSmall;
    margin-left: 10px;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    padding: 10px;
  }
}

.Note {
  width: 100%;
  h4 {
    margin-left: 3px;
  }
  .formType {
    @include flexCol;
    gap: $padding * 1.5;
    width: 100%;
  }

  .actions {
    @include flex;
    justify-content: flex-end;
    align-items: center;
  }

  .autosaveNotification {
    text-align: right;
    font-size: 12px;
    color: $gray500;

    &.autosaveError {
      color: $errorText;
    }
  }
}

.hoverable {
  position: relative;

  .closeIcon {
    position: absolute;
    top: -3px;
    right: -3px;
    display: none;
    cursor: pointer;
    width: 10px;
    height: 10px;
  }

  &:hover {
    .closeIcon {
      display: block;
    }
  }
}

.actionWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: $padding;

  .submittedStamp {
    display: flex;
    align-items: center;
    gap: $padding;
    margin-right: auto;
  }
}

.time {
  align-items: flex-start;
  gap: $padding / 2;
  max-width: 370px;
  // Since this is multiple inputs it can get messed up when it's min-width is set
  // from another style sheet
  min-width: min-content !important;

  button {
    border: 1px solid $primary400;
    color: $parioBlue;
    display: flex;
    @include center;
    height: 38px;
    border-radius: $borderRadius;
    padding: 0 $padding/2;
    gap: $padding/4;
  }

  label {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgb(52, 64, 84);
    margin: 4px;

    &.hiddenLabel {
      display: none;
    }
  }

  @mixin iconStyles {
    position: absolute;
    width: min-content;
    top: 10px;
    padding: 0;
    background-color: transparent;
  }

  .timeInputs {
    font-size: $fontBase;
    font-weight: $fontReg;
    display: flex;
    gap: $padding / 2;

    .inputWrapper {
      position: relative;
      @include flex;
      align-items: center;
      .customElement {
        position: absolute;
        right: 12px;
      }
    }

    input {
      line-height: 24px;
      font-family: inherit;
      padding: 5px 12px;
      background-color: $white;
      border: 1px solid $gray300;
      border-radius: $borderRadius;
      color: $gray500;
      width: 100%;

      &:disabled {
        cursor: not-allowed;
        background-color: $gray50;
      }
    }
  }
}

.MedicalCodingGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 90px 60px;
  &.isReadOnly {
    grid-template-columns: 2fr 3fr 2fr 90px;
    .encounterBillable {
      grid-column: span 4;
    }
  }
  border-bottom: $gridBorder;
  .header {
    border-radius: 0;
    border-top: $gridBorder;
    border-right: $gridBorder;
    &:nth-child(2) {
      border-left: $gridBorder;
    }
  }

  .encounterBillable {
    // column should span full width;
    grid-column: span 5;
    // and align at the :end
    justify-self: end;
    display: flex;
    @include center;
    gap: $padding;
    margin-bottom: $padding;
    margin-right: $padding;
    color: $gray500;
    font-size: $fontBase;
  }

  .cell {
    @include flexCol;
    gap: $padding;
    padding: $padding;
    border-right: $gridBorder;
    border-top: $gridBorder;

    .icd10grid {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: $padding;
      .icd10description {
        // make ellipses if overflows width:
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: $fontBase;
      }
    }

    &.code {
      flex-direction: row;
      align-items: flex-start;
      gap: $padding;
    }

    &.first {
      border-left: $gridBorder;
      button {
        height: min-content;
      }
    }

    .icd10Input {
      display: flex;
      justify-content: space-between;
    }
  }
}
