@import "@/styles/variables.scss";

.StatusGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding/2;
  word-break: break-all;
  // even children should be brighter
  > *:nth-child(odd) {
    color: $white;
  }
  .hasTooltip {
    cursor: pointer;
  }
}
