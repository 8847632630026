@import "@/styles/variables.scss";

.LabOrdering {
  height: 600px;
  overflow: scroll;

  .wrapper {
    @include flexCol;
    gap: $padding;
    margin: 0 2px;

    .overflowContainer {
      @include flexCol;
      gap: $padding;

      &.hidden {
        display: none;
      }
    }

    .error {
      @include flex;
      color: $gray500;
      font-size: $fontSmall;
      background: $error;
      padding: $padding/2;
      border-radius: $borderRadius;
      align-items: flex-start;
      gap: $padding/2;

      .content .label {
        color: $errorText;
      }

      .content ul {
        padding-left: $padding;
      }

      .content ul li {
        color: $errorText;
      }
    }

    .note {
      @include flex;
      color: $gray500;
      font-size: $fontSmall;
      background: $primary50;
      padding: $padding/2;
      border-radius: $borderRadius;
      align-items: center;
      gap: $padding/2;
    }

    .itemDetail {
      @include flexCol;
      color: $primary700;
      font-size: $fontSmall;
      border-radius: $borderRadius;
      border: 0.875px solid $primary50;
      background: $primary50;
      box-shadow:
        0px 0px 0px 3.5px #e0eaf1,
        0px 0.875px 1.75px 0px rgba(16, 24, 40, 0.05);
      transition: all 250ms ease;
      margin: 0 3px; // accounts for box shadow

      &.emptyState {
        background-color: $gray100;
        color: $gray700;

        button {
          display: none;
        }

        border: none;
        box-shadow: none;
        opacity: 55%;
        border: 1px dashed $gray300;
        margin: 0;
      }

      .itemName {
        @include flex;
        flex: 1;
        justify-content: space-between;
        align-self: stretch;
        padding: 10px 12px;
      }

      .testOptions {
        display: none;

        &.open {
          @include flexCol;
          gap: $padding/2;
        }

        background-color: $white;
        padding: 10px 12px;

        .testOption {
          @include flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .orderForm {
    @include flexCol;
    gap: $padding;

    .orderEntryDetail {
      @include flexCol;
      gap: $padding/4;
    }
  }

  .selections {
    display: flex;
    gap: $padding;
  }

  .orderDetail {
    @include flexCol;

    .label {
      border-bottom: $gridBorder;
      margin-bottom: $padding/2;

      &:not(:first-of-type) {
        margin-top: $padding/2;
      }

      padding-bottom: $padding/4;
    }

    .selectedTests {
      @include flex;
      gap: $padding/2;
      flex-wrap: wrap;
    }
  }
}

.spinner {
  @include spinner;
}
