@import "@/styles/variables.scss";

.Tooltip {
  position: relative;

  // The text bubble of the tooltip
  .tooltipText {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear;
    width: 120px;
    background-color: $gray500;
    color: $white;
    text-align: center;
    padding: $padding/4;
    border-radius: $borderRadius;
    position: absolute;
    z-index: 1;
    font-size: $fontSmall;

    // The little caret on the tooltip that points to the hovered children
    &::after {
      content: " ";
      position: absolute;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $gray500 transparent transparent transparent;
    }
  }

  /**
  * Positioning for different types of tooltips
  */
  // Standard top is center aligned and above
  &.standardTop {
    .tooltipText {
      bottom: 100%;
      left: 50%;
      margin-left: -60px;

      &::after {
        top: 100%;
        left: 50%;
      }
    }
  }

  // Right aligned top is above but right aligned so the tooltip expands to the left
  &.rightAlignedTop {
    .tooltipText {
      bottom: 100%;
      left: 0;

      &::after {
        top: 100%;
        left: 15%;
      }
    }
  }

  &:hover {
    .tooltipText {
      opacity: 1;
      visibility: visible;
    }
  }
}
