@import "@/styles/variables.scss";

.AssignGenericStaffTask {
  @include flexCol;
  gap: $padding/2;
  width: 450px;
  .buttons {
    @include flex;
    justify-content: flex-end;
    gap: $padding/2;
  }
}
