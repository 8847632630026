@import "@/styles/variables.scss";

.ImmunizationList {
  height: 300px;
  min-width: 500px;
  &.isExpanded {
    height: 700px;
    min-width: 750px;
  }
  overflow-y: auto;
  padding: $padding;
  .title {
    margin-bottom: $padding;
  }

  .left, .right {
    @include flex;
    align-items: center;
    gap: $padding / 2;
    width: min-content;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .filterWrapper {
    @include flex;
    align-items: flex-start;
    gap: $padding;
    margin-bottom: $padding;

    span {
      margin-top: 1px;
    }
  }

  .tabbedContent {
    @include flexCol;
  }

  .note {

    gap: $padding / 2;
    padding: $padding $padding / 2;
    background-color: $gray50;

    .display{
      @include flex;
      align-items: center;
      justify-content: space-between;
      .left, .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left, .right {
          display: flex;
          align-items: center;
          gap: $padding / 2;
        }
      }
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $successBorder;
    }
  }

  }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $successBorder;
    }


  .buttons {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;
    button {
      width: calc(50% - $padding /2);
    }
  }



.CreateImmunization {
  height: 100%;
  @include flexCol;
  @include center;
  form  {
    margin-top: $padding;
    @include flexCol;
    gap: $padding;
    width: 100%;
    height: 100%;
  }
}
