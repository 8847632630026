@import "@/styles/variables.scss";

.Content {
  height: 150px;
  width: 500px;
}

.prompt {
  text-align: center;

  p {
    margin: $padding 0;
  }
}

.buttons {
  display: flex;
  justify-content: center;

  button {
    margin: calc($padding/2) $padding;
  }
}
