@import "@/styles/variables.scss";

.standardFlow {
  width: 100%;

  .slide {
    transform: translateY(0px);
    transition: transform 750ms ease;

    &.LeftPaneOpen {
      margin-top: 20px;
      padding-left: 295px;
      transform: translateY(-75px); // height of the patient header
    }
  }

  .flowWrapper {
    padding: $padding;
    @include flexCol;
    gap: $padding;
  }

  .examTab {
    border-bottom: 3px solid transparent;
    padding: $padding/2;
    h4 {
      color: $primary400;
    }

    &.selected {
      border-color: $primary400;
      background-color: $blue50;
      h4 {
        color: $gray900;
      }
    }
  }

  .visitHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;

    .left {
      display: flex;
      align-items: center;
      gap: $padding;
      button {
        text-align: left;
      }
      .icon {
        button {
          text-align: left;
        }
      }
    }

    &.showSnapshot {
      .left {
        .icon {
          button {
            background-color: $primary50;
          }
        }
      }
    }
  }

  .visit {
    @include flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 250, 251, 1);
    border: 1px solid rgba(240, 240, 240, 1);
    padding: $padding/2 $padding * 1.5;
    gap: $padding;
    font-size: $fontBase;
    font-weight: $fontSemibold;

    .dropdowns {
      @include flex;
      gap: $padding;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;

      > div {
        flex: 1;
      }
    }

    .date {
      flex-wrap: nowrap;
      white-space: nowrap;
      flex: 1;
      justify-content: flex-end;
    }
    > div {
      @include flex;
      align-items: center;
      width: fit-content;
      gap: $padding/2;
      :first-child {
        flex-shrink: 0;
      }

      p {
        font-weight: $fontReg;
        color: $lightGrey;
      }
    }
  }

  .header {
    background-color: $lightBlue;
    padding: $padding;
    @include roundTop;

    h3 {
      font-weight: $fontSemibold;
      color: $medGrey;
      font-size: $fontMd;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $gray400;
  }

  .actionWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: $padding;
    .submittedStamp {
      display: flex;
      align-items: center;
      gap: $padding;
      margin-right: auto;
      flex-wrap: nowrap;
      button {
        max-width: 174px;
      }
    }
  }

  .cardWrapper {
    display: flex;
    gap: $padding;

    .menuCards {
      @include flexCol;
      gap: $padding;
    }

    .accordions {
      width: 100%;
      @include flexCol;
      gap: $padding;
      width: 100%;

      .inputWrapper {
        @include flex;
        gap: $spaceSm;
      }
    }
  }

  .togglePrivate {
    display: flex;
    justify-content: space-between;
    padding: $padding;

    &.blue {
      color: $gray400;
      background-color: $gray800;
    }

    &.lightBlue {
      color: $gray700;
      background-color: $parioLightBlue;
    }

    .toggleText {
      width: 90%;
      display: flex;
      flex-direction: row;
      gap: $padding;
      text-align: left;
      align-items: center;
      height: 100%;
      div:first-child {
      font-weight: bold;
      }
    }
  }
}

.SubmittedStamp {
  @include flex;
  flex-wrap: nowrap;
  width: 400px;
  gap: 6px;
  color: $gray500;
  .icon {
    &:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      transform: translate(-2.5px, -2px);
      background-color: $success;
      border-radius: 50%;
      opacity: 50%;
    }
    margin-top: 1.5px;
  }

  &.UnsubmittedStamp {
    .icon {
      &:before {
        background-color: $warning;
        transform: translate(-1.5px, -2px);
      }
    }
  }
}
