@import "@/styles/variables.scss";

.StartNewConversation {
  @include flexCol;
  gap: $padding;

  .optionTiles {
    display: grid;
    gap: $padding;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  .group {
    @include flexCol;
    gap: $padding/4;
  }
  .label {
    @include textXsMedium;
    color: $gray700;
  }
  .actions {
    @include flex;
    justify-content: flex-end;
    gap: $padding;
    margin-top: auto; // Pushes the actions to the bottom, regardless of the content above
  }

  // vertical grid on mobile
  @media (max-width: 767px) {
    gap: $padding/2;
    min-height: 80vh;
    .optionTiles {
      display: grid;
      gap: $padding/2;
      grid-template-columns: 1fr;
    }
    img {
      width: 14px;
      height: 14px;
    }
    .actions {
      @include flexCol;
      justify-content: flex-end;
      gap: $padding/2;
      margin-top: auto; // Pushes the actions to the bottom, regardless of the content above
      button {
        width: 100%;
      }
    }
  }
}
