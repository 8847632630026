@import "@/styles/variables.scss";

.FacePage {
  @include flexCol;
  align-items: center;
  gap: $padding;
  height: 100vh;

  .buttonWrapper {
    @include flex;
    justify-content: space-between;
  }

  .pastAppointments {
    overflow: scroll;
    @include flexCol;
    gap: $padding;
  }

  .faceCard,
  .medicalStateCard {
    @include flexCol;
    border-radius: $borderRadius;
    padding: $padding * 1.5 $padding;
    gap: $padding;
    background-color: transparent;
    width: 100%;
    background-color: $primary600;
    p {
      color: $primary100;
    }

    .header {
      @include flex;
      align-items: center;
      gap: $padding/2;

      .headerData {
        @include flexCol;
        gap: $padding/2;
        h4 {
          color: $primary50;
          font-size: $fontSemibold;
          font-weight: $fontSemibold;
        }
        h5 {
          color: $darkGrey;
          font-size: $fontSemibold;
          font-weight: $fontReg;
        }
        .nameNote {
          @include flex;
          align-items: flex-start;
          gap: $padding/2;
        }
        .privateNote {
          margin-top: 3px;
        }
      }
    }

    .content {
      @include flexCol;
      gap: $padding / 2;
    }
    .statusGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $padding;
      word-break: break-all;
      .span2 {
        grid-column: span 2;
      }
    }
  }

  .medicalStateCard {
    padding-bottom: $padding/2;
    button {
      margin-left: auto;
    }
  }

  .appointment {
    @include flexCol;
    gap: $padding;
    width: 100%;
    border-radius: $borderRadius;
    padding: $spaceMd $padding;
    border: 1px solid $parioBlue;

    h4 {
      color: $darkGrey;
      font-weight: $fontSemibold;
    }
    .dateWrapper {
      color: $parioBlue;
    }
    .dateWrapper,
    .tags {
      @include flex;
      align-items: center;
      gap: $padding / 2;
    }
  }
  .banner {
    align-items: center;
    background-color: $gray25;
    border: 1px solid $gray300;
    padding: $padding;
    border-radius: $borderRadius;
    margin-bottom: $padding;

    &.warningBanner {
      display: flex;
      align-items: flex-start;
      gap: $padding/2;
      color: $warningText;
      background-color: $warning50;
      .iconWrapper {
        margin-top: 2px;
      }
    }
  }

  .hasTooltip {
    cursor: pointer;
  }
}
