@import "@/styles/variables.scss";

.ClaimTimeline {
  @include flexCol;
  padding: $padding;
  gap: $padding;
  flex: 1;
  height: calc(100vh - 110px);

  .timelineContent {
    margin-bottom: auto;
    @include flexCol;
    gap: $padding;
    flex: 1;
    overflow: auto;
  }

  .timelineEntry {
    display: grid;
    grid-template-columns: 70px 1fr;
    gap: $padding;

    .timelineCard {
      @include card;
      border: 1px solid transparent;
      box-shadow: none;
      margin-bottom: $padding/4;
      border-color: $blueLight700;
      color: $blueLight700;
      font-size: $fontBase;
      .title {
        @include flex;
        justify-content: space-between;
        @include roundTop;
        padding: $padding/2;
        background-color: $blueLight50;
      }
      &.noComment {
        .title {
          @include roundBottom;
        }
      }
      .comment {
        @include roundBottom;
        padding: $padding/2;
      }

      &.SUBMITTED {
        .title {
          @include roundBottom;
        }
        .comment {
          display: none;
        }
      }

      &.DENIED_PENDING_PROVIDER_REVIEW,
      &.PARTIALLY_DENIED,
      &.REJECTED_BY_CLEARINGHOUSE,
      &.DENIED {
        border-color: $errorBorder;
        color: $errorText;
        .title {
          background-color: $error50;
        }
      }
      &.IN_REVIEW,
      &.PATIENT_BALANCE,
      &.CHANGES_REQUIRED {
        border-color: $warning700;
        color: $warning700;
        .title {
          background-color: $warning50;
        }
      }
      &.ACCEPTED,
      &.APPROVED {
        border-color: $successBorder;
        color: $successText;
        .title {
          background-color: $success25;
        }
      }
    }
  }
}
