@import "@/styles/variables.scss";

.InteractiveForm {
  // styles for interactive forms
  @include flexCol;
  gap: $padding/2;
  height: 100vh;
  width: 960px;

  @media (max-width: 768px) {
    width: unset;
  }
}
