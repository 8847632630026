@import "@/styles/variables.scss";

.AllergyList {
  height: 300px;
  padding: $padding;
  @include flexCol;
  flex: 1;
  &.isExpanded {
    height: 550px;
    min-width: 750px;
    padding: 0;
  }
  overflow-y: auto;
  .title {
    margin-bottom: $padding;
  }

  .content {
    @include flexCol;
    flex: 1;
  }

  .left,
  .right {
    @include flex;
    align-items: center;
    gap: $padding / 2;
    width: min-content;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .filterWrapper {
    @include flex;
    align-items: flex-start;
    margin-bottom: $padding;
    span {
      margin-top: 1px;
    }
  }

  .tabbedContent {
    @include flexCol;
  }

  .note {
    gap: $padding / 2;
    padding: $padding/2;
    background-color: $gray50;
    margin-top: -$padding;

    .display {
      @include flex;
      align-items: center;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left,
        .right {
          display: flex;
          align-items: center;
          gap: $padding / 2;
        }
      }
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $successBorder;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $successBorder;
}

.buttons {
  @include flex;
  align-items: center;
  justify-content: space-between;
  gap: $padding;
  margin-top: 10px;
  button {
    width: calc(50% - $padding / 2);
  }
}

.addButton {
  @include flex;
  align-items: center;
  justify-content: space-between;
  gap: $padding;
  margin-top: auto;
  button {
    width: calc(50% - $padding / 2);
  }
}

.CreateAllergy {
  @include flexCol;
  @include center;
  form {
    @include flexCol;
    gap: $padding/4;
    width: 100%;
    height: 100%;
  }
}

.NoKnownToggle {
  .noneRecorded {
    padding-bottom: $padding;
    color: $gray500;
    @include flexCol;
    gap: $padding/2;
  }
}
