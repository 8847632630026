@import "@/styles/variables.scss";

.Modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9; // make sure it's above everything else
  top: 0;
  background-color: rgba(16, 24, 40, 0.5);
  display: none;
  font-size: $fontBase;

  h4 {
    color: $gray900;
  }

  &.isOpen {
    @include flex;
    @include center;
  }

  .card {
    @include card;
    padding: $padding * 1.5;
    box-shadow:
      0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    max-width: 80vw;
    max-height: calc(100vh - 20px);
    overflow-y: scroll;

    .header {
      @include flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $padding;

      h4 {
        font-weight: $fontSemibold;
        color: $gray900;
      }

      .buttonClose {
        padding: 10px 0px 10px 10px;
      }
    }
  }

  &.fullWidth {
    // Giving the modal as much width as we can
    @media (max-width: 1600px) {
      .card {
        max-width: 1250px;
      }
    }

    @media (max-width: 1200px) {
      // The screen is really small, remove padding and give it obviously scrolling
      // This doesn't look super great for the progress update form but it's functional
      .card {
        overflow-x: scroll;

        &::-webkit-scrollbar {
          color: $parioBlue;
          width: 10px;
          height: 10px;
          display: block;
        }

        &::-webkit-scrollbar-track {
          background: $gray100;
          border-radius: $borderRadius;
        }

        &::-webkit-scrollbar-thumb {
          background: $parioBlue !important;
          visibility: visible;
          display: block !important;
          border-radius: $borderRadius;
        }
      }
    }
  }
}

.StaffUpsertModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: $padding;
  padding: $padding;
}

.AppointmentTypeUpsertModal,
.TaskBundleUpsertModal {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: $padding;
  padding: $padding;

  .buttons {
    // doing this because the bottom of the encounter type dropdown gets cut off
    margin-top: 100px;
    @include flex;
    justify-content: space-between;
    gap: $padding;
  }
}

.AddNewPayorModal {
  padding: $padding;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $padding;
    padding: $padding;

    .payorWrapper {
      p {
        text-align: center;
        margin: $padding / 2 0;
        color: $gray400;
        font-weight: $fontBold;
        text-transform: uppercase;
      }

      button {
        width: 100%;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: $padding;
  }
}

.SecretNote {
  min-width: 500px;
  max-width: 500px;
  min-height: 350px;
  @include flexCol;

  .content {
    @include flexCol;
    gap: $padding;
    flex: 1;
    padding: $padding 0;

    button {
      margin-top: auto;
    }
  }
}

.ProblemList {
  .header {
    @include flex;
    align-items: center;
  }

  min-height: 350px;
  width: 50rem;

  button {
    margin-left: auto;
  }
}

.GenericFormModal,
.ViewFile,
.DisplayPDF {
  width: 750px;
}

.GenericFormModal {
  width: 100%;
}

.ViewFile,
.DisplayPDF {
  height: 100%;
  min-height: 50vh;
}

.DisplayPDF {
  .contentViewer {
    height: calc(100% - 52px); // magic number from left pane header height
  }
}

.AddMedication {
  width: 750px;

  .FieldGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // creates two columns of equal width
    grid-gap: 10px;

    .FieldTitle {
      font-size: $fontBase;
      font-weight: $fontSemibold;
    }
  }

  .Buttons {
    margin-top: 20px;
  }
}

.Confirm,
.CopyFaxes,
.ForwardFaxes {
  min-width: 340px;

  .title {
    padding: 0 $spaceLg;
    color: $medGrey;
  }

  .message {
    color: $lightGrey;
    padding: $spaceMd $spaceLg;
  }

  .buttons {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $spaceMd;
    padding: 0 $spaceLg;
  }

  &.CopyFaxes {
    // Hardcoded height that looks good with the max height of the combobox
    // dropdown so it doesn't get cutoff
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.SendFilesForReview,
.ConfirmDeletePatient {
  min-width: 340px;

  .title {
    padding: 0 $padding/2;
    color: $medGrey;
  }

  p {
    margin-bottom: $padding/2;
    color: $gray600;
  }

  .message {
    @include flexCol;
    gap: $padding;
    padding: 0 $padding/2;
    margin-bottom: $padding;
  }

  .buttons {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;
    padding: $padding/2;
    margin-top: 100px;
  }
}

.ConfirmUpdateLabFlowsheetDate {
  min-width: 340px;
  width: 500px;

  .title {
    padding: 0 $padding/2;
    color: $medGrey;
  }
  p {
    margin-bottom: $padding/2;
    color: $gray600;
  }
  .message {
    display: block;
  }
  .buttons {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;
    padding: $padding/2;
    margin-top: 100px;
  }
}

.InternalMessage {
  width: 75vw;

  .newMessageTitle {
    margin-bottom: 10px;
  }

  .recipientsSection {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $borderGrey;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .recipientsListing {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .recipientsTagList {
      margin-left: 10px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .buttonContainer {
    padding: $padding 0;
    display: flex;
    justify-content: flex-end;
  }
}

.InternalMessageConfirmation {
  text-align: center;
  padding: $padding;

  button {
    margin: 15px auto 0;
  }
}

@media (max-width: $tablet) {
  .Modal {
    .card {
      max-width: 100vw;
      width: 100vw;
    }
  }

  .InternalMessage {
    width: auto;
  }
}

.DeleteFilesConfirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .warning {
    padding: $padding 0;
  }

  .buttons {
    padding: $padding 0;
    display: flex;

    button {
      margin: 0 $spaceSm;
    }
  }
}

.FaxFiles,
.PastPregnancy {
  @include flexCol;

  // only for tablet and up
  @media (min-width: $tablet) {
    min-width: 500px;
  }

  min-height: 250px;

  ul {
    list-style-type: none;
  }

  .files {
    margin: $padding/2 0;
  }

  form {
    @include flexCol;
    gap: $padding/2;
    flex: 1;

    .buttons {
      @include flex;
      justify-content: space-between;
      margin-top: auto;
    }
  }

  .fieldGroupTitle {
    padding: $padding/2;
    margin-top: $padding;
    background-color: $blue100;
    @include roundTop;
    font-weight: $fontSemibold;
    color: $blue900;
  }

  .fieldWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding/2;
    width: 100%;
  }

  .buttons {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $padding;
  }
}

.DotPhraseCreation {
  padding: $padding;
  width: 50vw;

  h2 {
    margin-bottom: 15px;
  }

  .phraseFormContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .phraseInfo {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .textAreaContainer {
    border: solid 1px $borderGrey;
    border-radius: $borderRadius;
    padding: 5px 12px;
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    position: relative;
  }

  .textAreaContent {
    overflow: auto;
    font-family: inherit;
    line-height: 24px;
  }

  .editorInstructions {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .phraseContent {
    display: flex;
    flex-direction: column;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .menuContainer {
      display: flex;
      gap: 10px;
      margin-right: auto;
    }
  }
}

.SetStaffSchedule {
  @include flexCol;
  width: 450px;
  min-height: 300px;
  gap: $padding;

  .buttons {
    margin-top: auto;
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding/2;
  }

  .timeSelector {
    width: 100%;
  }
}

.ConfirmFileMove {
  @include flexCol;
  min-width: 450px;
  min-height: 300px;
  gap: $padding;

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding;
    margin-top: auto;
  }
}

.EncounterEdit {
  min-width: 450px;
  @include flexCol;
  gap: $padding/2;

  .header {
    text-align: center;
    margin-bottom: $padding;
  }

  .timeContainer {
    display: flex;
    gap: $padding;
    align-items: center;
    margin-bottom: $padding;
  }
}

@import "@/styles/variables.scss";

.ShareRecordsModal {
  display: flex;
  flex-direction: column;
  width: 70vw;

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding/2 $padding;

    .fullWidth {
      grid-column: 1 / span 2;
    }
  }

  textarea {
    line-height: 24px;
    font-family: inherit;
    padding: 5px 12px;
    background-color: $white;
    border: 1px solid $gray300;
    border-radius: $borderRadius;
    color: $gray500;
    display: block;
    width: 100%;
  }

  label {
    font-size: $fontBase;
    margin: 4px;
    display: inline-block;
    color: $medGrey;
    font-weight: $fontSemibold;
  }

  .buttonContainer {
    display: flex;
    gap: 5px;
  }

  .recordTransferForm {
    display: flex;
    flex-direction: column;
    gap: $spaceMd;
  }

  .fileListChecks {
    display: flex;
    flex-direction: column;
    gap: $spaceSm;
    margin: $spaceSm 0;

    label {
      margin: 0;
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .coverSheet {
    page-break-after: always;
    text-align: center;
    padding: 200px;
    width: 595px;
    height: 842px;
  }

  .ErrorMessage {
    margin-top: $spaceMd;
    padding: $spaceSm;
    max-width: 75%;
    align-self: center;

    border-color: $errorBorder;
    color: $errorText;
    background-color: $error;
  }

  .transferLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;

    img {
      animation: spin 4s linear infinite;
      stroke: $borderGrey;
      color: $borderGrey;
      width: 40px;
      height: 40px;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.AddTransaction {
  width: 350px;

  form {
    @include flexCol;
    gap: $padding/2;
  }

  .buttons {
    margin-top: $padding * 2;
  }
}

.TransactionDetails {
  width: 650px;
}

.LabOrderingTemplate {
  width: 750px;
}

.loadingIcon {
  animation: spin 4s linear infinite;
  stroke: #fff;

  &.dark {
    stroke: $medGrey;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.LoadingSpinner {
  padding: $padding * 2 $padding * 4;
}

.ConcludePregnancy {
  width: 60vw;
  // Just a solid round number that looks pretty good
  max-width: 650px;

  p.sectionHeader {
    font-weight: bold;
    margin-bottom: $padding / 2;
    margin-top: $padding;
  }

  .inputRow {
    display: flex;
    gap: $padding;
    margin-bottom: $padding / 2;

    label {
      margin: 0;
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .buttonContainer {
    margin-top: $padding;
    display: flex;
    justify-content: flex-end;
  }
}

.CreateInfantChart form {
  min-width: 640px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding/2 $padding;

  .fullWidth {
    margin-top: $padding * 5;
    grid-column: 1 / span 2;
  }
}

.TaskCreation {
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: $padding;
    margin-bottom: $padding * 2;
    min-width: 35vw;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: $padding * 8;
  }
}

.ViewClaim {
  display: grid;
  grid-template-columns: minmax(1020px, 1fr) 300px;
  gap: $padding;
}

.PregnancyDating {
  .buttonContainer {
    display: flex;
    padding: $padding 0;
    gap: $padding;
    justify-content: flex-end;
  }
}

.LmpEstimate {
  width: 60vw;

  .eddLabel {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgb(52, 64, 84);
    margin: 4px;
    cursor: pointer;

    sup {
      border: solid 1px $lightGrey;
      color: $lightGrey;
      border-radius: 50%;
      padding: 0 4px;
    }
  }

  .eddText {
    line-height: 24px;
    padding: 5px;
    font-size: 14px;
    gap: $padding;
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: $padding / 2;
    margin-bottom: $padding;

    .lmp {
      display: flex;
      gap: $padding;
    }
  }

  .fileSelect {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $padding / 2;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      padding-top: 15px;
      gap: $padding / 2;

      span {
        opacity: 0;
        transition: all 0.1s;
      }

      &:hover {
        span {
          opacity: 1;
        }
      }
    }
  }

  .buttonContainer {
    display: flex;
    gap: $padding;
    justify-content: flex-end;
    padding-top: $padding;
  }
}

.TaskCompletionModal,
.TaskCreationInfo {
  min-width: 45vw;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $padding;
  }

  .buttonContainer {
    margin-top: $padding * 2;
    display: flex;
    justify-content: flex-end;
  }
}

.fileList {
  @include flexCol;
  gap: $padding;
}

.file {
  @include flex;
  justify-content: space-between;
  gap: $padding/2;
  overflow: hidden;
  background-color: $blue25;
  padding: $padding;
  border-radius: $borderRadius;

  &.selected {
    background-color: $blue100;
    border: 1px solid $blue700;

    .fileName {
      color: $blue700;
    }
  }

  .fileName {
    white-space: nowrap;
    overflow: hidden;
    color: $gray500;
  }

  .fileTags {
    @include flex;
    margin-top: $padding/2;
    gap: $padding/2;
  }
}

.DemographicsTaskModal {
  min-width: 600px;
  @include flexCol;
  gap: $padding;
  margin-bottom: 2 * $padding;
}

@media (max-width: 767px) {
  .DemographicsTaskModal {
    min-width: unset;
  }
}

.PatientRecall {
  min-width: 600px;
  @include flexCol;
  gap: $padding;
  margin-bottom: 2 * $padding;

  .recallForm {
    display: flex;
    flex-direction: column;
    gap: $padding;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: $padding;
  }
}

.ViewFile {
  min-width: 1000px;
}

.EditHealthHistory {
  width: 50vw;

  .inputContainer {
    display: flex;
    justify-content: space-between;
    gap: $padding;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    padding-top: $padding;
  }
}

.LabResults {
  @include flex;
  padding: $padding;
  gap: $padding;
  min-width: 850px;
  max-width: calc(
    100vw - 80px
  ); // make sure it doesn't overflow the screen, leave room for the "x" button

  .resultsWrapper {
    @include flexCol;
    gap: $padding;
    flex: 1;
    overflow: auto;
    scrollbar-width: thin;
    max-height: calc(100vh - 100px);
  }

  .orderInfo {
    .orderHeader {
      @include flexCol;
      gap: $padding;
      color: $gray900;

      .testMetadata {
        display: flex;
        gap: $padding;
        padding: $padding;
        background-color: $primary200;
        border-radius: $borderRadius;

        .resultNote {
          background-color: $white;
          padding: $padding;
          border-radius: $borderRadius;
          color: $gray500;
        }
      }

      .buttons {
        @include flex;
        gap: $padding;
        justify-content: flex-start;
      }
    }
  }

  .annotationWrapper {
    @include flexCol;
    gap: $padding;
    align-items: center;
    padding: $padding;
    min-width: 400px;
    max-height: 90vh;

    .overflow {
      @include flexCol;
      gap: $padding;
      align-items: center;
      padding: $padding;
      overflow-y: scroll;
      min-width: 400px;
    }

    .addWrapper {
      @include flex;
      padding-bottom: $padding / 2;
      border-bottom: $gridBorder;
      position: relative;

      .addAnnotation {
        color: $gray400;
        font-size: $fontLg;
        width: 100%;
        transition: all 150ms ease;

        &:hover {
          color: $white;
          background-color: $parioBlue;
        }
      }
    }

    .emptyState {
      @include flexCol;
      @include center;
      height: 100%;
      text-align: center;
      gap: $padding;
      height: 180px;

      .addAnnotation {
        color: $gray400;
        font-size: $fontLg;
        border: 1px solid $gray300;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin: auto;
      }
    }
  }
}

.DocumentReview {
  width: 70vw;
  max-width: 1400px;
  min-width: 1280px;

  .header {
    .title {
      margin-top: $padding;
      padding-bottom: $padding;
      position: relative;
      display: flex;

      .editableTitle {
        min-width: 1px;
        white-space: pre-wrap;
        word-break: break-word;
        caret-color: currentColor;

        &:focus {
          outline: none;
          background-color: rgba(0, 50, 110, 0.1);
        }

        &:hover + .editIcon {
          opacity: 1;
        }
      }

      .editIcon {
        position: absolute;
        right: -10px; // Position it just outside the text
        top: -10px; // Align with text top
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }

    .actions {
      @include flex;
      gap: $padding;
      margin-left: $padding;
      align-items: flex-end;

      .field {
        width: 100%;
        gap: calc($padding/2);

        .patientSelect {
          position: relative;
          flex: 1;
          min-width: 200px;

          .phone {
            position: absolute;
            margin-left: calc($padding/2);
            margin-top: calc($padding/4);
          }
        }
      }
    }
  }

  .body {
    @include flex;
    @include center;
    height: 75vh;

    &.hasBackground {
      padding: calc(2 * $padding);
      background: $gray100;
    }
  }
}

@media (max-width: 767px) {
  .Modal {
    height: unset;
    font-size: $fontSmall;
    .card {
      max-width: 100vw;
      width: 100vw;
      height: 100vh;
      padding: $padding/2;
      border-radius: 0;
      .header {
        margin-bottom: $padding/2;
      }
    }
  }
}
