@import "@/styles/variables.scss";

.LabFlowsheetForm {
  @include flexCol;
  gap: $padding;
  margin-bottom: 10vh;

  .templateTests {
    @include flexCol;
    height: calc(100vh - 150px);
    overflow-y: auto;
    gap: $padding/2;
  }

  .templateTest {
    @include flexCol;
    gap: $padding/2;
  }

  .analyteName {
    font-weight: $fontBold;
    color: $gray700;
    margin-left: 2px;
    margin-bottom: -6px;
    font-size: $fontSmall;
  }

  .analyteRow {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: $padding/2;
  }
}
