@import "@/styles/variables.scss";

.LaborForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding/2 $padding;
  .fullWidth {
    grid-column: 1 / span 2;
  }

  &.Medicationform,
  &.NewbornProgressform {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    .fullWidth {
      grid-column: 1 / span 4;
    }

    .content {
      padding: none;
    }
  }

  /**
  * Progress update form styles
  */
  &.BirthProgressUpdateForm,
  &.MaternalProgressForm,
  &.NewbornProgressForm,
  &.ProgressUpdateform {
    grid-template-columns: repeat(8, 1fr);

    .progressSection {
      flex-direction: column;
      border: solid 1px $parioBlue;
      padding: $padding;
      border-radius: $borderRadius;
      background: rgba($parioBlue, 0.1);

      legend {
        font-weight: $fontBold;
        color: $parioBlue;
        padding: 0 $padding/2;
      }

      .inputs {
        display: flex;
        gap: $padding / 2;
      }
    }

    .progressTime {
      padding: $padding;
      border-radius: $borderRadius;
      border-width: 1px;

      legend {
        padding: 0 $padding/2;
        font-weight: $fontBold;
        color: $gray500;
        font-size: 14px;
      }
    }
  }

  .header {
    color: $parioBlue;
    font-size: $fontBase;
    font-weight: $fontSemibold;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-bottom: $padding / 2;
    border-bottom: $gridBorder;
    margin-top: $padding;
  }

  .fieldsetTextArea {
    background: $white;
    border-radius: $borderRadius;
    margin-top: $padding;
  }

  // Labor specific update form styles
  &.ProgressUpdateform {
    .progressSection {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: $padding / 2;
    }
  }

  .progressSection {
    grid-column: span 4;
  }
  .fullWidth {
    grid-column: 1 / span 8;
  }
  .spanOne {
    grid-column: span 1;
  }
  .spanTwo {
    grid-column: span 2;
  }
  .spanThree {
    grid-column: span 3;
  }
  .spanSix {
    grid-column: span 6;
  }
  .time {
    align-items: flex-start;
  }
}

.NewbornFormWrapper {
  @include card;

  .formHeader {
    padding: $padding;
    color: $parioBlue;
    background-color: $blue50;
    font-weight: $fontSemibold;
    @include roundTop;
  }
}

.NewbornForm {
  padding: $padding;
  gap: $padding * 3;
  @include flexCol;
  gap: $padding * 1.5;

  label {
    color: $gray700;
    font-size: $fontBase;
    font-weight: $fontSemibold;
  }
  .header {
    p {
      color: $parioBlue;
      font-size: $fontBase;
      font-weight: $fontSemibold;
    }

    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-bottom: $padding / 2;
    border-bottom: $gridBorder;
    justify-content: space-between;
  }
  .vitals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding * 4;
    .fieldWrapper {
      @include flexCol;
      gap: $padding/2;
      label {
        color: $gray700;
        white-space: nowrap;
      }
    }
  }

  .reflexes {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(50px, 1fr);
    .options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
      }
    }
  }

  .physical {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding/2 $padding * 4;
    .row {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }
    .fullWidth {
      grid-column: 1 / span 2;
    }
    .fieldPair {
      display: flex;
      flex-direction: column;
      gap: $padding / 2;
      margin-bottom: $padding;
    }
  }
}

.ApgarForm {
  @include flexCol;
  gap: $padding;
  margin: $padding/2 0;
  .header {
    background-color: $gray50;
    color: $gray400;
    font-size: $fontSmall;
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    align-items: center;
    white-space: nowrap;
    padding: $padding;
    border-bottom: $gridBorder;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    align-items: center;
    white-space: nowrap;
    padding: $padding/4 $padding;
    border-bottom: $gridBorder;
    color: $gray500;
    font-size: $fontBase;

    p {
      font-size: $fontSmall;
    }
  }
}

.LaborForm,
.ApgarForm {
  .time {
    display: flex;
    align-items: flex-end;
    gap: $padding;
    button {
      border: 1px solid $primary400;
      color: $parioBlue;
      display: flex;
      @include center;
      height: 38px;
      border-radius: $borderRadius;
      padding: 0 $padding/2;
      gap: $padding/4;
    }
  }
}

.newInfantChartAlert {
  display: flex;
  flex-direction: column;
  gap: $padding;
  padding: 0 $padding * 2;
}

.additionalChecks {
  max-height: 260px;
  display: flex;
  margin-bottom: $padding;
  gap: $spaceSm;
  flex-basis: 220px;

  .additionalFiles,
  .additionalData {
    flex: 1;
    max-height: 100%;
  }

  .checkListHeader {
    display: flex;

    > div {
      flex: 1;
      align-content: flex-end;
    }
  }

  .subCheckList {
    max-height: 400px;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: 20px !important;
    scrollbar-color: $parioBlue;

    &::-webkit-scrollbar {
      color: $parioBlue;
      width: 10px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background: $gray100;
      border-radius: $borderRadius;
    }

    &::-webkit-scrollbar-thumb {
      background: $parioBlue !important;
      visibility: visible;
      display: block !important;
      border-radius: $borderRadius;
    }
  }

  .fileListChecks {
    display: flex;
    flex-direction: column;
    gap: $spaceSm;
    margin: $spaceSm 0;
    min-height: 220px;
    padding: 4px;

    label {
      margin: 0;
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }

    .subCheckList,
    .additionalSubCheckList {
      padding-left: $padding;
      display: flex;
      flex-direction: column;
      gap: $spaceSm;
      margin: $spaceSm 0;
      margin-top: 0;
    }

    .additionalSubCheckList {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}

.summaryButtons {
  display: flex;
  flex-direction: column;
  gap: $padding;
  grid-column: 1 / span 2;
}

.SummaryForm {
  .fullWidth {
    display: flex;
    flex-direction: column;
    gap: $padding;
  }
}

.DetailTimelineForm {
  display: flex;
  flex-wrap: wrap;
  gap: $padding / 2;

  > div {
    flex: 1 40%;

    &.fullWidth {
      flex: 1 100%;
    }
  }
}
