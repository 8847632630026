@import "@/styles/variables.scss";

.Accordion {
  padding: 0 $padding / 2;
  position: relative;

  .title {
    color: $parioBlue;
    font-weight: $fontSemibold;
    font-size: 18px;
    line-height: 28px;
    &.smallTitle {
      font-size: $fontBase;
      line-height: unset;
    }
  }

  &.secondary {
    padding: 0;
    @include card;
    .header {
      @include blueHeader;
      font-size: 18px;
      line-height: 28px;
    }
    .content {
      padding: 0;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }

  &.tertiary {
    @include card;
    .header {
      @include blueHeader;
      font-size: 16px;
      background-color: white;
    }
    .content {
      padding: 0;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }

  &.unpadded {
    .content {
      padding-top: 0;
    }
  }

  &.CheckableAccordion {
    @include card;
    .header {
      @include blueHeader;
      border-radius: $borderRadius;

      h4 {
        color: $blue900;
      }
    }
  }

  .header {
    @include header;
    padding: 0;
    border-bottom: $gridBorder;
    gap: $padding / 2;

    .right {
      display: flex;
      align-items: center;
      gap: $padding / 2;
      padding: 4px 0;
      width: fit-content;
      margin-left: auto;
      flex-shrink: 0;
      padding: $padding / 2;
      &.fullWidth {
        margin-left: 0;
        width: 100%;
      }
    }

    .left {
      @include flex;
      align-items: center;
      gap: $padding / 2;
      padding: 4px 0;
      width: fit-content;
      overflow: hidden;
      text-align: left;

      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    overflow: hidden;

    p {
      flex-wrap: nowrap;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
    }
  }

  .content {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    padding-top: 0;
    transition:
      max-height 0.2s ease,
      opacity 0.2s ease,
      visibility 0s 0.2s;
  }

  &.isOpen {
    .header {
      border-radius: 0;
      @include roundTop;
    }

    .content {
      max-height: 20000px; /* Adjust based on expected content size */
      opacity: 1;
      visibility: visible;
      padding-top: $padding;
      transition:
        max-height 0.2s ease,
        opacity 0.2s ease,
        visibility 0s 0s;
    }
  }
}
