@import "@/styles/variables.scss";

.ClaimOutcome {
  .outcomeForm {
    width: 100%;
    display: grid;
    grid-template-columns: 70px 90px repeat(11, minmax(60px, 1fr)) 70px;
    &.isSuperbillView {
      grid-template-columns: repeat(4, minmax(60px, 1fr));
    }
    color: $gray500;
  }
  .claimOutcomeHeader {
    font-size: $fontSmall;
    background-color: $gray50;
    padding: $padding $padding/2;
    border-bottom: $gridBorder;
  }

  .cell {
    font-size: $fontSmall;
    padding: $padding $padding/2;
    border-bottom: $gridBorder;
    min-width: 50px;
    text-wrap: wrap;

    &.code {
      display: flex;
      gap: $padding/2;
      flex-wrap: wrap;
    }

    .result {
      display: flex;
      align-items: center;
      gap: $padding/4;
    }
  }

  .total {
    font-size: $fontBase;
    font-weight: $fontSemibold;
    padding: $padding;
    background-color: $gray50;
    border-top: $gridBorder;
    @include flex;
    justify-content: flex-end;
  }
}
