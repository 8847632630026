@import "../../styles/variables.scss";

.LabModal {
  display: grid;
  grid-template-columns: 1fr minmax(300px, 2fr) 2fr;
  height: 600px;
}

.LabOrdering {
  padding: 0 $padding;
  text-align: center;
}

.TestSelection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: $padding;
  padding: 0 $padding;
  max-width: 1000px;
}

.TestBundleModal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: $padding;
  padding: 0 $padding;
  max-width: 1000px;
}

.SelectableCard {
  @include card;
  @include flex;
  flex-direction: column;
  align-items: center;
  background-color: $gray100;
  padding: $padding;
  border: 1px solid transparent;
  margin-bottom: $spaceSm;
  font-size: $fontSmall;
  cursor: pointer;

  &.selected {
    border-color: $primary600;
  }
}

.Results {
  max-width: 100%;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .loading {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.FilterableSelect {
  img {
    align-self: center;
  }

  input {
    margin-bottom: $padding;
  }

  .organization {
    padding-bottom: $spaceSm;

    button {
      width: 100%;
    }
  }
}

.LabOrder {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 3px $spaceSm;
  white-space: nowrap;
}

.Operations {
  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ButtonGroup {
      display: flex;
      margin-top: auto;
      align-self: center;

      button:not(:last-child) {
        margin-right: $spaceSm;
      }
    }
  }
}

.Locations {
  .FilterableSelect {
    width: 100%;
    margin-right: $spaceMd;
    h3 {
      padding-bottom: $spaceMd;
    }

    .Results {
      margin-bottom: $spaceSm;

      .SelectableCard {
        align-items: flex-start;
        width: 95%;
      }
    }
  }

  form {
    display: flex;
    align-items: center;

    div {
      margin-right: $spaceSm;
      margin-top: auto;
    }
  }
}

.BundleDetails {
  display: flex;
  flex-direction: column;

  h3 {
    padding-bottom: $padding/2;
  }

  .selectedTests {
    @include flexCol;
    gap: $padding/2;
    margin-top: $padding * 1.5;
    height: 400px;
    overflow: auto;
    display: flex;
  }

  .selectedTest {
    background-color: $gray100;
    padding: $padding/2 $padding/4;
    border-radius: $borderRadius;
    @include flexCol;
    gap: $padding/2;
  }

  .editBundleName {
    margin-bottom: $spaceMd;
  }

  .operations {
    align-self: flex-end;
    display: flex;
    margin-top: auto;

    div {
      margin-right: $spaceSm;
      &:last-child {
        margin-right: 0;
      }

      button {
        white-space: nowrap;
      }
    }
  }
}

.TestRow {
  display: grid;
  grid-template-columns: 450px 150px 150px;
  align-items: center;
  width: 100%;
  text-align: left;
  p {
    @include textSmMedium;
    text-overflow: wrap;
    white-space: wrap;
  }
}
.OrderResultsHeader {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  padding-bottom: $spaceSm;

  button {
    margin-right: $spaceSm;
  }
}

.LabResults {
  padding: $padding/2 0;
  .gridWrapper {
    max-width: calc(100% - $padding * 3);
    margin-top: $padding;
    margin-bottom: $padding;
    margin-left: $padding * 3;
    overflow: scroll;
    scrollbar-width: thin;
  }
}
