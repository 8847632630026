@import "@/styles/variables.scss";

// temp: chatGPT converted the checkmark into a dataURI
// need to debug why url to svg not working
$dataURI: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEgMy42MzI4MUw0LjUgOS4xMzI4MUwyIDYuNjMyODEiIHN0cm9rZT0iIzE2ODNDMCIgc3Ryb2tlLXdpZHRoPSIxLjY2NjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==";

@mixin iconStyles {
  position: absolute;
  width: min-content;
  top: 10px;
  padding: 0;
  background-color: transparent;
}

.Input {
  max-width: 320px;
  position: relative;
  font-size: $fontBase;
  font-weight: $fontReg;

  &.noMaxWidth {
    max-width: unset;
  }

  &.small {
    max-width: 110px;
  }

  &.large {
    min-width: 350px;
  }

  &.marginBottom {
    margin-bottom: $padding;
  }

  &.fullWidth {
    max-width: unset;
  }

  &.horizontal {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;

    label {
      margin: 0;
    }
  }

  &:not(.fitWidth) {
    width: 100%;
  }

  .inputWrapper {
    position: relative;
    @include flex;
    align-items: center;

    .customElement {
      position: absolute;
      right: 12px;
    }

    &.centered {
      justify-content: center;
    }

    ul {
      list-style: none;
      position: absolute;
      cursor: default;
      z-index: 10;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      height: auto;

      &.open {
        border: $gridBorder;
        padding: $spaceSm 0;
        @include card;
      }

      &.top {
        bottom: 100%;
        left: 0;
        transform: translateY(0);
      }

      li {
        font-size: $fontSmall;
        padding: $spaceSm $padding;
        color: $darkGrey;
      }
    }
  }

  &.condensed {
    input {
      font-size: $fontSmall;
      line-height: unset;
      padding: $padding/4;
      border-radius: $borderRadius/2;
    }
  }

  // form elements
  input {
    line-height: 24px;
    font-family: inherit;
    padding: 5px 12px;
    background-color: $white;
    border: 1px solid $gray300;
    border-radius: $borderRadius;
    color: $gray500;
    width: 100%;

    // file input styles
    &[type="file"]::file-selector-button {
      border: $gridBorder;
      padding: 3px 6px;
      margin-right: 6px;
      border-radius: 4px;
      background-color: $grey200;
      transition: 200ms;
      color: $lightGrey;
      font-size: $fontSmall;
    }

    &[type="file"]::file-selector-button:hover {
      background-color: $highlightBlue;
    }

    // checkbox styles
    &[type="checkbox"] {
      /* remove standard background appearance... */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radio button appearance */
      display: inline-block;
      width: 15px;
      min-width: 15px;
      height: 15px;
      padding: 3px;
      border-radius: 4px;
      border: 1px solid $gray400;
      cursor: pointer;
      position: relative;
      background-color: $white;

      &:checked {
        &:before {
          content: url(#{$dataURI});
          position: absolute;
          top: -4px;
          right: 1px;
        }

        border: 1px solid #1683c0;
        background-color: #e5f3fb;
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $gray50;
    }

    &.hidden {
      display: none;
    }

    &.hasError {
      border-color: $error;
    }
  }

  ::placeholder {
    color: $gray400;
    opacity: 1;
    /* Firefox */
  }

  label {
    display: inline-block;
    color: $gray700;
    margin: 4px 2px;
    font-size: $fontSmall;

    &.hidden {
      @include visuallyHidden;
    }
  }

  &.disabled {
    label {
      color: $lightGrey;
    }
  }

  .validationError {
    background-color: $error;
    padding: 0px 4px;
    margin-left: 6px;
    color: $errorText;
    font-size: 10px;
    position: absolute;
  }

  &.Select {
    &.fullWidth {
      max-width: unset;
    }

    .inputWrapper {
      position: relative;

      &.paddedLeft {
        input {
          padding-left: 30px;
        }
      }

      input {
        cursor: pointer;
        color: $medGrey;

        &:disabled {
          cursor: not-allowed;
          background-color: $gray50;
        }
      }

      .close {
        @include iconStyles();
        right: 10px;
      }

      .clear {
        @include iconStyles();
        background-color: $grey200;
        color: $gray600;
        top: 9px;
        padding: 1px 5px;
        right: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $blue100;
        }
      }

      .iconStart {
        @include iconStyles();
        left: 10px;
      }
    }

    ul {
      list-style: none;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(100%);
      overflow-y: auto;
      cursor: default;
      z-index: 10;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;

      &.open {
        border: $gridBorder;
        padding: $spaceSm 0;
        @include card;
      }

      li {
        font-size: $fontSmall;
        padding: $spaceSm 14px;
        color: $darkGrey;
      }
    }
  }

  &.MultiSelect {
    .inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border: $gridBorder;
      border-radius: $borderRadius;
      padding: $padding/2;

      &.hasError {
        border-color: $errorBorder;
      }

      .selectedItems {
        @include flex;
        flex-wrap: wrap;
        gap: 4px;
        flex-grow: 1;
      }

      .selectedItem {
        display: flex;
        align-items: center;
        background-color: $grey200;
        border-radius: $borderRadius;
        padding: $padding/4 $padding/2;
        margin-right: $padding/4;
        font-size: $fontSmall;
        color: $gray700;

        .removeButton {
          background-color: transparent;
          border: none;
          cursor: pointer;
          margin-left: $padding/4;
        }
      }

      input {
        flex-grow: 1;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.Toggle {
  position: relative;
  @include flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 36px;
  height: 18px;
  background: $white;
  border: 1px solid $primary600;
  border-radius: 10px;
  transition: all 2s ease;

  &.small {
    width: 24px;
    height: 12px;

    span {
      width: 10px;
      height: 10px;
    }

    .toggleCheckbox {
      &:checked {
        +span {
          right: 1px;
        }
      }

      &:not(:checked) {
        +span {
          right: 13px;
        }
      }
    }
  }

  &.large {
    width: 48px;
    height: 24px;

    $togglePadding: 2px;
    $toggleRadius: 18px;

    span {
      width: $toggleRadius;
      height: $toggleRadius;
      top: $togglePadding;
    }

    .toggleCheckbox {
      &:checked {
        +span {
          right: $togglePadding;
        }
      }

      &:not(:checked) {
        +span {
          right: calc(100% - $toggleRadius - #{$togglePadding});
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background-color: $gray50;
    border-color: $gray400;

    span {
      background: $gray400;
    }
  }

  &.checked {
    background-color: $blue100;

    span {
      background: $primary600;
    }
  }

  span {
    position: absolute;
    top: 1px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: 0.2s;
    background-color: $primary400;
    transition: all 350ms ease;
  }

  label {
    position: absolute;
    transform: translateX(calc(-100% - 6px));
    font-size: $fontSmall;
    white-space: nowrap;

    &.labelRight {
      transform: translateX(40px);
    }
  }

  .toggleCheckbox {
    visibility: hidden;
    position: absolute;

    &:checked {
      +span {
        right: 2px;
      }
    }

    &:not(:checked) {
      +span {
        right: 19px;
      }
    }
  }
}

.OptionsField {
  &.centered {
    text-align: center;
  }

  .centered {
    display: flex;
    justify-content: center;
  }
}

.Checkbox {
  label {
    margin: 0;
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }
}

.WeightInput {
  max-width: 320px;

  label {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgb(52, 64, 84);
    margin: 4px;
  }

  .horizontalLayout {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
  }

  .weightInputContainer {
    max-width: 100%;
    display: flex;
    background-color: #fff;
    border: 1px solid rgb(208, 213, 221);
    padding: 5px 5px;
    border-radius: 8px;
    gap: 5px;

    @media (max-width: 1440px) {
      padding: 5px 0;
    }

    input {
      min-width: 0;
    }

    .inputWithUnit {
      flex: 1;
      line-height: 24px;
      font-family: inherit;

      align-items: center;

      color: rgb(102, 112, 133);
      width: 100%;
      min-width: 0;
      display: flex;
      height: 100%;

      input {
        border: none;
        text-align: right;
        margin-right: 2px;
        line-height: 24px;
        min-width: 10px;
        flex: 1;
      }

      span {
        //min-width: 0;
        display: inline;
        color: $gray300;
        flex-shrink: 1;
        min-width: 0;
        overflow-x: hidden;
        text-wrap-mode: nowrap;
      }
    }
  }

  p {
    text-align: right;
    color: $gray500;
  }
}
