@import "../../styles/variables.scss";

.Items {
  background: $white;
  border-radius: $borderRadius/2;
  border: 1px solid $borderGrey;
  box-shadow: 2px 2px 4px rgba(16, 24, 40, 0.05);
  color: $darkGrey;
  font-size: $fontBase;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  padding: 10px 0;

  .item {
    background: transparent;
    display: block;
    margin: 0;
    padding: 5px 10px;
    text-align: left;
    width: 100%;

    &.isSelected {
      background: #bde4ff;
    }
  }
}
