@import "@/styles/variables.scss";

.Demographics {
  @include flexCol;
  gap: $padding;

  .colWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding;
  }

  @media (max-width: 1600px) {
    .colWrap {
      grid-template-columns: 1fr;
    }
  }

  &.oneCol {
    .colWrap {
      display: grid;
      grid-template-columns: 1fr;
      gap: $padding;
    }
  }
  .exportButton {
    margin-left: auto;
  }
}

.EmergencyContactListing {
  .contactListItem {
    form,
    .item {
      display: grid;
      grid-template-columns: [form] 9fr [controls] 1fr;
      gap: $padding / 2;

      .formFields {
        grid-column: form;
        display: flex;
        gap: $padding / 2;
        flex-wrap: wrap;

        > div {
          flex: 2;
          min-width: 125px;
        }
      }

      .flexOne,
      .listingControls {
        flex: 1;
      }

      .listingControls {
        display: flex;
        align-items: flex-end;
        gap: $padding / 2;
        grid-column: controls;
        padding: $padding;
        padding-left: 0;
      }
    }

    .buttonContainer {
      padding: $padding;
    }

    .item {
      // I just guess and checked this to get it to line up nicely
      padding-top: 23px;
    }

    form .formFields {
      padding: $padding;
    }
  }
}
