@import "@/styles/variables.scss";

.ViewHeader {
  @include flex;
  align-items: center;
  gap: $padding;
  background-color: $gray100;
  padding: $padding / 2 0;

  &.noBackground {
    background-color: transparent;
  }

  .actionsWrapper {
    @include flex;
    gap: $padding;
    align-items: center;
    width: fit-content;
    margin-left: auto;
  }
}
