@import "@/styles/variables.scss";

.SideBar {
  @media (max-width: 500px) {
    display: none;
  }

  @include flexCol;
  align-items: flex-start;
  justify-content: space-between;
  height: 100vh;
  background-color: $secondary;
  color: $primary;
  width: $sidebarWidth;
  padding: $spaceLg $spaceMd;
  gap: $spaceSm;
  z-index: 4;
  position: fixed;

  .sidebarLink {
    .linkLabel {
      font-weight: $fontMd;
      font-size: $fontBase;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #c2d2db;
        opacity: 0;
        transform: translateY(-100%);
        transition: all 0.2s;
      }
    }

    &:hover,
    &:focus {
      .linkLabel {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  div {
    @include flexCol;
    gap: $spaceMd;
  }

  // Do not print sidebar
  @media print {
    display: none;
  }
}
