@import "@/styles/variables.scss";

.PatientMedicalState {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: $padding/2;
  width: 100%;
  font-size: $fontSmall;
  color: $gray100;
  background-color: $primary600;
  padding: $padding;
  border-radius: $borderRadius;
}
