@import "@/styles/variables.scss";

.ProblemList {
  height: 300px;
  @include flexCol;
  overflow-y: scroll;
  padding: $padding;
  &.isExpanded,
  &.readOnly {
    height: 550px;
    min-width: 750px;
    margin: 0;
  }

  &.isExpanded {
    padding: 0;
  }

  &.readOnly {
    div[role="tablist"] {
      display: none;
    }
  }

  overflow-y: auto;

  .left,
  .right {
    @include flex;
    align-items: center;
    gap: $padding / 2;
    width: min-content;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .filterWrapper {
    @include flex;
    margin-bottom: $padding;
    gap: $padding/2;

    span {
      margin-top: 1px;
    }
  }

  .tabbedContent {
    @include flexCol;
  }

  .content {
    @include flexCol;
    flex: 1;
  }

  .note {
    gap: $padding / 2;
    padding: $padding $padding / 2;
    background-color: $gray50;

    .display {
      @include flex;
      align-items: flex-start;
      justify-content: space-between;
      .left {
        flex: 1;
      }
      .right {
        justify-content: space-between;
      }
      .left,
      .right {
        display: flex;
        align-items: flex-start;

        .left,
        .right {
          display: flex;
          align-items: center;
          gap: $padding / 2;
        }
      }
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $successBorder;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $successBorder;
}

.buttons {
  @include flex;
  align-items: center;
  justify-content: space-between;
  gap: $padding;
  margin-top: auto;

  button {
    width: calc(50% - $padding / 2);
  }
}

.CreateProblem {
  @include flexCol;
  @include center;
  flex: 1;
  form {
    @include flexCol;
    gap: $padding;
    width: 100%;
    flex: 1;

    .buttons {
      justify-self: flex-end;
      margin-top: auto;
    }
  }
}
