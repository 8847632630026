@import "@/styles/variables.scss";

.Alert {
  @include flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fontBase;
  border-radius: $borderRadius;
  padding: $padding;
  margin-top: $spaceSm;

  .message {
    @include flex;
    align-items: center;
    gap: $padding;
  }
  &.hide {
    display: none;
  }

  &.warning {
    color: $warningText;
    background-color: $warning25;
    border: 1px solid $warningBorder;
  }
}
