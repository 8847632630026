@import "@/styles/variables.scss";

.ToastTrough {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80vw;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: $padding;
  gap: $padding;
  pointer-events: none;
  overflow: hidden;

  @media print {
    display: none;
  }
}
.ToastAlert {
  padding: $padding $padding * 1.5 $padding $padding;
  border-radius: $borderRadius;
  font-size: $fontBase;
  font-weight: 500;
  border: 2px solid transparent;
  display: flex;
  gap: $padding / 4;
  align-items: flex-start;
  pointer-events: all;
  cursor: pointer;
  position: relative;
  min-width: 200px;
  max-width: 300px;

  ::first-letter {
    text-transform: capitalize;
  }
  &.success {
    background-color: $success25;
    border-color: $successBorder;
    color: $successText;
    stroke: $successText;
  }
  &.error {
    background-color: $error25;
    border-color: $errorBorder;
    color: $errorText;
    stroke: $errorText;
  }
  &.warning {
    background-color: $warning25;
    border-color: $warningBorder;
    color: $warningText;
    stroke: $warningText;
  }
  &.info,
  &.loading {
    background-color: $info;
    border-color: $infoBorder;
    color: $infoText;
    stroke: $infoText;
  }
  button {
    position: absolute;
    right: 6px;
    top: 0;
  }

  .spinner {
    margin-top: 2px;
    border: 2px solid $infoText;
    border-left-color: $lightBlue;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
  }

  .loadingIcon {
    animation: spin 4s linear infinite;
    stroke: $parioBlue;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
