@import "@/styles/variables.scss";

.DotPhraseEdit {
  padding: 35px 40px 0px;
  min-height: 100vh;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    h1 {
      margin-right: auto;
      color: $gray800;
    }
  }

  .phraseTable {
    width: 100%;
    font-size: $fontBase;
    color: $lightGrey;
    background-color: $white;
    border-radius: $borderRadius;
    border: $gridBorder;
    border-spacing: 0;
    box-shadow: $shadowXs;

    thead {
      font-size: $fontSmall;
      background-color: $gray50;

      tr {
        th {
          text-align: left;
          text-transform: capitalize;
          padding: $padding;
          min-width: 150px;

          &:first-of-type {
            border-top-left-radius: $borderRadius;
          }

          &:last-of-type {
            border-top-right-radius: $borderRadius;
          }
        }
      }
    }

    tbody {
      font-weight: $fontReg;

      tr {
        background-color: $white;

        td {
          border-top: $gridBorder;
          padding: $padding;
        }

        &:last-child {
          td {
            &:first-of-type {
              border-bottom-left-radius: $borderRadius;
            }

            &:last-of-type {
              border-bottom-right-radius: $borderRadius;
            }
          }
        }
      }
    }
  }

  .phraseList {
    padding-left: 0px;
    list-style: none;
    .phraseItem {
      padding: 15px;
      border-bottom: 1px solid $borderGrey;
    }
  }
}

.phraseButton {
  svg {
    margin-right: 15px;
    stroke: $gray500;
  }

  &:hover {
    cursor: pointer;

    svg {
      stroke: $gray800;

      g {
        fill: $gray800 !important;
      }
    }
  }
}
