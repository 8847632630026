@import "@/styles/variables.scss";

.MilestoneMarker {
    box-shadow: $shadowMd;
    border-radius: $borderRadius;
    background-color: none;
    position: relative;

    .timeStamp {
        position: absolute;
        box-shadow: none;
        z-index: 1;
        font-size: 10px;
        top: -6px;
        right: -2px;
        background-color: $white;
        border-radius: 50%;
    }

    &.completed {
        color: $blue900;
        border-color: transparent;
        opacity: 0.5;
    }
    &.selected {
        box-shadow: none;

        button {
            border-color: $primary400;
            color: $blue900;
            opacity: 1;
            background-color: $blue50;
        }
    }
    &.highlighted {
        button {
            border-color: $warningText;
            color: $warningText;
            background-color: $warning25;
        }
    }
}
