@import "@/styles/variables.scss";

.LabFlowsheetFileViewer {
  .contentWrapper {
    display: flex;
    position: relative;
    height: calc(100vh - 250px);
    gap: $padding/2;
  }

  .renderer {
    flex: 1;
    min-width: 0;

    .noFileSelected {
      @include flex;
      height: 100%;
      @include center;
      background-color: $gray50;
      .content {
        @include flexCol;
        align-items: center;
        justify-content: space-between;
        gap: $padding;
        .spanDecorator {
          background-color: $grey200;
          color: $gray500;
          padding: $padding/2;
          border-radius: $borderRadius;
          font-weight: $fontSemibold;
        }
      }
    }
  }
}
