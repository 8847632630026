.LaborFlow {
    width: 100%;

    .headerControls {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
}
