@import "@/styles/variables.scss";

.Confirm {
  min-width: 340px;

  .title {
    padding: 0 $spaceLg;
    color: $medGrey;
  }

  .message {
    color: $lightGrey;
    padding: $spaceMd $spaceLg;
  }

  .buttons {
    @include flex;
    align-items: center;
    justify-content: space-between;
    gap: $spaceMd;
    padding: 0 $spaceLg;
  }
}
