@import "@/styles/variables.scss";
/* pario wordmark logo */

.Logo {
  display: flex;
  align-items: center;
  gap: $spaceSm;
  font-size: $fontXL;
  color: $secondary;
  cursor: pointer;
  &.secondary {
    color: $primary;
  }
}
