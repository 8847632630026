@import "@/styles/variables.scss";

.LaborFlow {
  width: 100%;

  .flowWrapper {
    @include flexCol;
    gap: $padding;
  }

  .visitHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $padding;

    .left {
      display: flex;
      align-items: center;
      gap: $padding;
      button {
        text-align: left;
        &:hover {
          background-color: $primary50;
        }
      }

    }

    &.showSnapshot {
      .left {
        button {
          background-color: $primary50;
        }
      }
    }

  }

  .right {
    display: flex;
    justify-content: right;
    gap: $padding / 4;
  }

  .header {
    background-color: $lightBlue;
    padding: $padding;
    @include roundTop;

    h3 {
      font-weight: $fontSemibold;
      color: $medGrey;
      font-size: $fontMd;
    }
  }

  .birthRecord {
    @include flexCol;
    gap: $padding;

    .tabContent {
      &.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .timelineWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      margin-bottom: $padding * 2;

      scroll-behavior: smooth;
      margin-top: $padding;
      gap: $padding;
      align-items: flex-start;
    }

    .milestoneWrapper {
      display: flex;
      flex-direction: column;
      margin-top: $padding;
    }

    .milestones {
      @include flex;
      align-items: center;
      gap: $padding/4;
      flex-wrap: wrap;

      &.vertical {
        flex-wrap: wrap;
        width: 100%;
        align-content: flex-start;
      }
    }
  }

  .cardWrapper {
    display: flex;
    gap: $padding;

    .menuCards {
      @include flexCol;
      gap: $padding;

    }

    .accordions {
      width: 100%;
      @include flexCol;
      gap: $padding;
      width: 100%;

      .inputWrapper {
        @include flex;
        gap: $spaceSm;
      }
    }
  }

  .assessmentPlan {
    margin-bottom: $padding;
  }

  .submitStamp {
    @include flex;
    gap: $padding/4;
    align-items: center;
    color: $gray500;
    .icon {
      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        transform: translate(-2.5px, -2px);
        background-color: $success;
        border-radius: 50%;
        opacity: 50%;
      }
    }
  }
}

.CodingBilling {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $padding;

  .submitStamp {
    @include flex;
    gap: $padding/4;
    align-items: center;
    color: $gray500;
    .icon {
      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        transform: translate(-2.5px, -2px);
        background-color: $success;
        border-radius: 50%;
        opacity: 50%;
      }
    }
  }
}
