@import "@/styles/variables.scss";

.ProgressUpdateForm {
  display: flex;
  flex-wrap: wrap;
  gap: $padding / 2;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: $padding / 2;

  fieldset {
    border: solid 1px $parioBlue;
    border-radius: $borderRadius;
    padding: $padding / 2;
    background: rgba($parioBlue, 0.1);

    legend {
      font-weight: $fontBold;
      color: $parioBlue;
      padding: 0 $padding/2;
      font-size: 14px;
    }

    // Color variants
    &.bgLight {
      background: $white;

      legend {
        color: $gray500;
      }
    }
  }

  .dateTimeContainer {
    min-width: 215px;
  }

  .nowButtonContainer {
    display: flex;
    align-items: flex-end;

    .nowButton {
      border: 1px solid $primary400;
      color: $parioBlue;
      display: flex;
      @include center;
      height: 38px;
      border-radius: $borderRadius;
      padding: 0 $padding/2;
      gap: $padding/4;
    }
  }

  fieldset,
  .columnContainer {
    min-width: 235px;
    display: flex;
    flex-direction: column;

    .columnContainer {
      justify-content: space-between;
      height: 100%;
    }

    // Inputs within the fieldset
    .inputs {
      display: flex;
      flex-wrap: wrap;
      gap: $padding / 2;

      // Very generic selector to include any type of input, however it will only select
      // the DIRECT child of this input div
      > * {
        min-width: 140px;
        max-width: unset !important;
        // 19% makes it 4 inputs per row (it's not 20% because there's the gap that makes them
        // wrap if they're the full 20%
        flex: 1 1 calc(20% - $padding / 2);

        &.fullWidth {
          flex: 100%;
        }

        &.halfWidth {
          flex: 1 1;
          min-width: 50px;
        }

        &.flexUnset {
          flex-basis: unset !important;
          flex-grow: unset !important;
          flex-shrink: unset !important;
          min-width: unset !important;
        }
      }

      // If we want the inputs to shrink to fit in the row (max of 5 inputs b/c of the 140px)
      &.fitRow {
        > * {
          flex: 1 140px;

          &.halfWidth {
            flex: 1 1 0;
            min-width: 50px;
          }
        }
      }
    }

    // Spacing of fieldsets
    &.spanOne {
      grid-column: span 1;
      flex: 1;
    }

    &.spanTwo {
      grid-column: span 2;
      flex: 2;
    }

    &.spanThree {
      grid-column: span 3;
      flex: 3;
    }

    &.spanFour {
      grid-column: span 4;
      flex: 4;
    }

    &.spanFive {
      grid-column: span 5;
      flex: 5;
    }

    &.spanSix {
      grid-column: span 6;
      flex: 6 100%;
    }
  }
}
