@import "@/styles/variables.scss";

.OptionsMenu {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: $borderRadius;

  &.fullWidth {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
  }

  button {
    border: $gridBorder;
    border-right: none;
    padding: 10px $padding;
    background-color: $white;
    color: $gray700;
    font-size: $fontReg;
    white-space: nowrap;
    border-left: $gridBorder;
    transition: all 200ms ease-in;
    text-transform: capitalize;

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      border-right: $gridBorder;
    }

    &.selected {
      background-color: $primary500;
      color: $white;
    }

    &.danger {
      color: $errorText;
      border-color: $errorBorder;
      border-right: solid 1px;
      font-weight: $fontBold;

      + button {
        border-left: none;
      }

      &.selected {
        background-color: $errorText;
        color: $white;
      }
    }
  }
}
