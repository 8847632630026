@import "@/styles/variables.scss";

.Tag {
  display: flex;
  @include center;
  width: max-content;
  border-radius: 2 * $borderRadius;
  padding: 0px 8px;
  height: fit-content;
  flex-shrink: 0;
  white-space: nowrap;
  gap: $padding/4;
  width: fit-content;
  min-height: 18px; // if there is no text

  // center the remove button
  button {
    margin-bottom: 2px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  span {
    @include textXsMedium;
    line-height: unset;
    &.initialsIcon {
      border-radius: 50%;
      background-color: $primary700;
      color: white;
      width: 16px;
      height: 16px;
      font-size: 8px;
      display: flex;
      @include center;
      margin-left: -6px;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $white;
  }

  .bar {
    width: 6px;
    height: 4px;
    background-color: $white;
  }

  .icon {
    @include flexCol;
    margin-left: -2px;
  }

  &.success {
    background-color: $success;
    span {
      color: $successText;
    }
    .dot,
    .bar {
      background-color: $successBorder;
    }
  }

  &.error {
    background-color: $error;
    span {
      color: $errorText;
    }

    .dot,
    .bar {
      background-color: $errorBorder;
    }
  }

  &.warning {
    background-color: $warning;
    span {
      color: $warningText;
    }
    .dot,
    .bar {
      background-color: $warningBorder;
    }
  }

  &.info {
    background-color: $blue100;
    span {
      color: $blue700;
    }

    .dot,
    .bar {
      background-color: $infoTagText;
    }
  }

  &.disabled {
    span {
      color: $gray400;
    }
    background-color: $gray100;
  }

  &.infoGrey {
    span {
      color: $gray600;
    }
    background-color: $gray100;
    .dot,
    .bar {
      background-color: $gray500;
    }
  }

  &.location {
    background-color: white;
    color: $gray500;
  }

  &.recipient {
    background-color: white;
    border: 1px solid $primary700;
    a {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 8px;
        height: 8px;
      }
    }
  }

  &.pending {
    background-color: $calendarFourteen;
    span {
      color: $calendarOne;
    }
    .dot,
    .bar {
      background-color: $calendarOne;
    }
  }
}
